import React, { useState } from 'react';
import '../css/navslip.css'
import { useNavigate } from 'react-router-dom';

 export default function NavSlip() {
  const [navVisible, setNavVisible] = useState(false);

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  function NAvbarAlt(props){
    const [swep,setSwep]=useState(false)
    const TogleClik=()=>{
      setSwep(!swep)
    }
    return(
        <>
        <div className='navdown' onClick={TogleClik}>
        <h4>{swep ? <i className="bi bi-chevron-down"></i>: <i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {swep &&<>{props.p}</>}
        </div>
        </>
    )};
    
    const navigate = useNavigate();
    const openClick = (e) => {
      if (e.target.tagName === 'P') {
        const destination = e.target.getAttribute('data-url');
        const actionType = e.target.getAttribute('data-type'); 
        if (destination) {
          if (actionType === 'external') {
            window.open(destination, '_blank');
          } else {
            navigate(destination);
          }
        }
      }
    };

  return (
    <div className='slipe' onMouseLeave={toggleNav}>
      <div className={`toggle-btn ${navVisible ? 'active' : ''}`} onClick={toggleNav}>{navVisible ? <i className="bi bi-chevron-compact-left"></i>:<i className="bi bi-chevron-compact-right"></i>}</div>
      <div className={`sidebar ${navVisible ? 'visible' : ''}`}>
              <NAvbarAlt
              h1='permulaan'
              p={<div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
              </div>}/>
              <NAvbarAlt
              h1='rofa`'
              p={<div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
              </div>}/>
              <NAvbarAlt
              h1='nasab'
              p={<div onClick={openClick}>
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
              </div>}/>
              <NAvbarAlt
              h1='khofad'
              p={<div onClick={openClick}>
              <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
              </div>}/>
              <NAvbarAlt
              h1='mengikut'
              p={<div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
              </div>}/>
              <NAvbarAlt
              h1='sosial media'
              p={<div onClick={openClick}>
              <p data-url="https://instagram.com/khais1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281370397395" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
              </div>}/>
              <NAvbarAlt
              h1='traktir'
              p={<div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
              </div>}/>
      </div>
    </div>
  );
}
