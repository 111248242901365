import Navbar from './navbarApp'
import Bawah from './bawah';
import React, { useEffect,useRef } from 'react';
import NavSlip from './navSlip';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import '../css/halamanAwal.css'
import '../css/bawah.css'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// gambar
import techny from '../sourc/techny-school-supplies-for-school-subjects.gif'
import abstrak1 from '../sourc/science.gif'
import abstrak2 from '../sourc/popularity.gif'
import abstrak3 from '../sourc/present.gif'
import abstrak4 from '../sourc/library.gif'
import abstrak5 from '../sourc/marketing.gif'
import abstrak6 from '../sourc/having-job.gif'
import abstrak7 from '../sourc/forest-care.gif'
import abstrak8 from '../sourc/electric-car.gif'
import abstrak9 from '../sourc/bio-technology.gif'
import abstrak10 from '../sourc/abstract-sign-in.gif'
import abstrak11 from '../sourc/abstract-playing-table-tennis.gif'
import abstrak12 from '../sourc/abstract-open-space.gif'
import abstrak13 from '../sourc/abstract-list-is-empty.gif'
import abstrak14 from '../sourc/abstract-delivery.gif'
import abstrak15 from '../sourc/abstract-co-workers.gif'
import abstrak16 from '../sourc/abstract-browser-windows-with-emoji.gif'
import abstrak17 from '../sourc/abstract-brainstorm.gif'
import abstrak18 from '../sourc/abstract-10.gif'
import abstrak19 from '../sourc/abstract-9.gif'
import abstrak20 from '../sourc/abstract-7.gif'
export default function HalamanAwal(){ 
    useEffect(() => {
        AOS.init({duration: 1500, once: false });
      }, []);   

// halaman 
function Halaman(){
    let navigate = useNavigate();

    function ClickMulai() {
      navigate('/mulai');
    }
    return(
        <>
        <div className='e-card playing'>
        <Helmet>
          <title>belajar nahwu itu mudah</title>
          <meta name="description" content="Website tempat belajar ilmu nahwu dnegan secara singkat dan tepat dengan berpedoman kitab al-jurumiyah" />
        </Helmet>
            <div className='datess'>
              <div className='date'>
                <h1><span>belajar</span> nahwu itu mudah</h1>
                <p>Belajar ilmu nahwu dengan secara singkat dan tepat dengan berpedoman kitab al-jurumiyah</p>
                <button className='btn-shine' onClick={ClickMulai}><i className="bi bi-arrow-right-short"></i><span className='span'>mulai belajar</span></button>
              </div>
              <div className='img'><img src={techny} alt='gambar animasi'></img></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
        </div>
    </>
    )
}

function Halaman1(){
    const Keterangan=(props)=>{
        return(
            <>
            <div data-aos={props.animasi} className='halamansatu'>
                    <h1>{props.h1}</h1>
                    <p>{props.p}</p>
            </div>
            </>
        )
    }
    return(
        <>
        <div className='halaman1'>
            <Keterangan
             h1="apa itu ilmu nahwu"
             p={
                <>
                <p>ilmu nahwu adalah cabang ilmu bahasa arab yang mempelajari tata bahasa dan struktur kalimat dalam bahasa arab
                </p> 
                <p>dalam ilmu nahwu kita mempelajari aturan aturan yang berkaitan dengan susunan kata, perubahan kata, serta hubungan antara kata-kata dalam sebuah kalimat dalam bahasa arab.
                </p>
                <p>singkat nya. ilmu nahwu adalah ilmu tentang kaidah-kaidah dalam bahasa arab, ilmu nahwu merupakan salah satu cabang dari 13 ilmu arab, ilmu ini merupakan dasar gramatikal sastra arab yang sangat berperan penting dalam penulisan, pelafasan, pembacaan, bahkan pembicaraan yang mengandung text-text arab.
                </p></>}
             animasi="zoom-in"
             />
            <Keterangan
             h1="nahwu secara etimologi"
             p={
                <>
                <p>secara etimologi atau secara bahasa kata nahwu berasal dari kata al-nahwa kata ini kemudian di sesuaikan dengan lisan indonesia sehingga menjadi nahu</p>
                <p>ilmu nahwu di maknai dengan <u>al-qasd</u> karna dengan ilmu ini seseorang dapat mengetahui bahkan paham maksud dan tujuan suatu pembicaraan. ilmu ini juga di namai dengan <u>at-toriq</u> yang berarti jalan, dengan ilmu nahwu siapaun dapat lebih mudah mempelajari dan memahami ilmu ilmu arab lain nya seperti fiqih, balaghoh, mantiq, bayan, dan masih banya cabang ilmu arab lain nya ilmu nahwu juga di maknai dengan <u>al-misl</u> karna setiap pembahasan ilmu ini hampir tidak lepas dari pernyataan contoh dan misal</p>
                <p>sulit di bayang kan jika mempelajari suatu kaedah tanpa adanya pengetahuan tentang bagaimana penerapan nya, karna tujuan dari mempelajari suatu ilmu adalah untuk di terapkan.</p>
                </>
             }
             animasi="zoom-in"/>
            <Keterangan
             h1="nahwu secara terminologi"
             p={
                <>
                <p>secara terminologi atau istilah ilmu nahwu adalah ilmu tentang prinsip-prinsip dan kaidah-kaidah bahasa arab.</p>
                <p>fungsi nya untuk mengidentifikasi subuah kalimat atau kata bahasa arab dan bentuk ahiran nya karna faktor-faktor tertentu seperti pertemuan dengan kalimat lain, susuan kaliamat dan lain sebagainya.
                </p>
                </>
             }
             animasi="zoom-in"/>
         </div>
        </>
    )
}


// halaman 2
function Halaman2(){

    let navigate = useNavigate();

    function ClickKalimat(e) {
        navigate(e);
      }

    function CardAja(props){
        return(
        <>
        <div className="halamandua">
            <div className="card" data-aos={props.aos}>
                <img src={props.gambar} alt='gambar animasi'></img>
                <div className="card-info">
                  <h2>{props.h2}</h2>
                  <p>{props.p}</p>
                </div>
                <button onClick={props.klik}><i className="bi bi-arrow-right-short"></i>mulai belajar</button>
            </div>
        </div>
        </>
        )
    }

    const scrollContainerRef1 = useRef(null);
  
    const scrollLeft1 = () => {
      if (scrollContainerRef1.current) {
        scrollContainerRef1.current.scrollBy({ left: -300, behavior: 'smooth' });
      }
    };

    const scrollRight1 = () => {
        if (scrollContainerRef1.current) {
          scrollContainerRef1.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
      };

      const scrollContainerRef2 = useRef(null);

      const scrollLeft2 = () => {
        if (scrollContainerRef2.current) {
          scrollContainerRef2.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
      };
  
      const scrollRight2 = () => {
          if (scrollContainerRef2.current) {
            scrollContainerRef2.current.scrollBy({ left: 300, behavior: 'smooth' });
          }
        };

        const scrollContainerRef3 = useRef(null);

        const scrollLeft3 = () => {
          if (scrollContainerRef3.current) {
            scrollContainerRef3.current.scrollBy({ left: -300, behavior: 'smooth' });
          }
        };
    
        const scrollRight3= () => {
            if (scrollContainerRef3.current) {
              scrollContainerRef3.current.scrollBy({ left: 300, behavior: 'smooth' });
            }
          };

    return(
        <>
        <div className='cardAja'>
            <h1>bab-bab</h1>
            <h1>dalam ilmu nahwu</h1>
            <div className='cardBerjalan' ref={scrollContainerRef1}>
            <div className='geser'>
                <i className="bi bi-chevron-compact-left" onClick={scrollLeft1}></i>
                <i className="bi bi-chevron-compact-right" onClick={scrollRight1}></i>
            </div>
                <CardAja klik={() => ClickKalimat("/bab-kalam")} gambar={abstrak1} p="kalam adalah lafaz yang tersusun yang memberikan pemahaman dengan wadho" h2="بَابُ الكَلَا"/>
                <CardAja klik={() => ClickKalimat("/bab-ikrob")} gambar={abstrak2} p="I'rob adalah perubahan pada akhir kalimat karena perbedaan faktor yang masuk, secara lafaz / secara takdir." h2="بَابُا لإِعْرَابُ"/>
                <CardAja klik={() => ClickKalimat("/bab-makrifat")} gambar={abstrak3} p="bab menerangkan pertanda yang di rofa' kan" h2="بَابُا مَعْرِفَةِ عَلَامَاتِ اﻹِعْرَابِ"/>
                <CardAja klik={() => ClickKalimat("/faslun")} gambar={abstrak4} p="yang di'irob ada dua macam, yaitu :1 Jenis yang di i'rob dengan harokat; 2 Jenis yang di i'rob dengan huruf" h2="فَصْلٌ"/>
                <CardAja klik={() => ClickKalimat("/bab-afal")} gambar={abstrak5} p="Fi'il terbagi menjadi 3 : 1 Fi'il Madhi, 2 Fi'il Mudhari', 3 Fi'il Amr" h2="بَابُا اﻷَفْعَالِ"/>
                <CardAja klik={() => ClickKalimat("/bab-marfuatil-asma")} gambar={abstrak6} p="Isim-isim yang di rofa' kan ada 7" h2="بَابُا مَرْفُوْعَاتِ الأَسْمَاءِ"/>
                <CardAja klik={() => ClickKalimat("/bab-fail")} gambar={abstrak7} p="Fail' , yaitu isim marfu' yang terletak setelah fi'il" h2="بَابُا الفَاعِلِ"/>
                <CardAja klik={() => ClickKalimat("/bab-naibulfail")} gambar={abstrak8} p="Yaitu isim marfu' yang tidak disebutkan fa'il (pelaku) nya" h2="بَابُا المَفْعُوْلِ الَّذِي لَمْ يُسَمَّ فَاعِلُهُ"/>
                <CardAja klik={() => ClickKalimat("/bab-mubtadak-khobar")} gambar={abstrak9} p="Mubtada' adalah : Isim Marfu' yang terbebas dari 'amil-'amil lafzhiyah" h2="بَابُا المُبْتَدَأِ وَالخَبَرِ"/>
                <CardAja klik={() => ClickKalimat("/bab-awamilidakhilatialalmubtadakwakhobar")} gambar={abstrak10} p="Amil-amil yang masuk pada mubtada' dan khabar ada 3 jenis" h2="بَابُا العَوَامِلِ الدَّاخِلَةِ عَلَى المُبْتَدَأِ وَالخَبَرِ"/>
            </div>
            <div className='cardBerjalan' ref={scrollContainerRef2}>
            <div className='geser'>
                <i className="bi bi-chevron-compact-left" onClick={scrollLeft2}></i>
                <i className="bi bi-chevron-compact-right" onClick={scrollRight2}></i>
            </div>
                <CardAja klik={() => ClickKalimat("/bab-mansubatil-asma")} gambar={abstrak11} p="Isim-isim yang di-manshub kan ada 14" h2="بَابُا مَنْصُوْبَاتِ الأَسْمَاءِ"/>
                <CardAja klik={() => ClickKalimat("/bab-mafulunbih")} gambar={abstrak12} p="Maf'ul bih yaitu isim manshub yang menjadi objek dari pekerjaan fi'il " h2="بَابُا المَفْعُوْلِ بِهِ"/>
                <CardAja klik={() => ClickKalimat("/bab-masdar")} gambar={abstrak13} p="Mashdar yaitu isim manshub yang menempati posisi ketiga dalam tashrif fiil" h2="بَابُا المَصْدَرِ"/>
                <CardAja klik={() => ClickKalimat("/bab-zorofzaman-wamakan")} gambar={abstrak14} p="zaman keterangan waktu, makan keterangan tempat" h2="بَابُا ظَرْفِ الزَّمَانِ وَظَرْفِ المَكَانِ"/>
                <CardAja klik={() => ClickKalimat("/bab-hal")} gambar={abstrak15} p="Hal yaitu isim yang di-nashabkan yang menjelaskan suatu keadaan yang masih samar" h2="بَابُا الحَالِ"/>
                <CardAja klik={() => ClickKalimat("/bab-tamyiz")} gambar={abstrak16} p="Tamyiz adalah isim manshub yang menjelaskan suatu dzat yang masih samar" h2="بَابُا التَّمْيِيْزِ"/>
                <CardAja klik={() => ClickKalimat("/bab-istisna")} gambar={abstrak17} p="Huruf istitsna ada 8" h2="بَابُا اﻹِسْتِثْنَاءِ"/>
                <CardAja klik={() => ClickKalimat("/bab-laa")} gambar={abstrak18} p="ketentuan bagi isim la ada 3 macam" h2="بَابُا لَا"/>
                <CardAja klik={() => ClickKalimat("/bab-munada")} gambar={abstrak19} p="Munada itu ada 5 macam: Mufrod 'alam, Nakirah Maqshudah, Nakirah ghairu maqshudah, Mudhaf, Syabih bil-mudhaf" h2="بَابُا المُنَادَى"/>
                <CardAja klik={() => ClickKalimat("/bab-mafulminajlih")} gambar={abstrak20} p="Maf'ul min ajlih adalah isim manshub yang disebutkan untuk menjelaskan sebab terjadinya fi'il " h2="بَابُا المَفْعُوْلِ مِنْ أَجْلِهِ"/>
                <CardAja klik={() => ClickKalimat("/bab-mafulmaah")} gambar={abstrak5} p="Maf'ul ma'ah adalah isim manshub yang disebutkan untuk menjelaskan bersama siapa fi'il" h2="بَابُا المَفْعُوْلِ مَعَهُ"/>
            </div>
            <div className='cardBerjalan' ref={scrollContainerRef3}>
            <div className='geser'>
                <i className="bi bi-chevron-compact-left" onClick={scrollLeft3}></i>
                <i className="bi bi-chevron-compact-right" onClick={scrollRight3}></i>
            </div>
                <CardAja klik={() => ClickKalimat("/bab-mahfudotil-asma")} gambar={abstrak6} p="Makhfudhat itu ada 3 macam" h2="بَابُا مَخْفُوْضَاتِ الأَسْمَاءِ"/>
                <CardAja klik={() => ClickKalimat("/bab-naat")} gambar={abstrak7} p="na'at yaitu isim yang mengikuti man'ut (isim yang disifati)" h2="بَابُا النَّعْتِ"/>
                <CardAja klik={() => ClickKalimat("/bab-atof")} gambar={abstrak8} p="Huruf 'athaf ada 10" h2="بَابُا العَطْفِ"/>
                <CardAja klik={() => ClickKalimat("/bab-taukid")} gambar={abstrak9} p="Taukid itu isim yang mengikuti isim yang dikuatkan" h2="بَابُا التَّوْكِيْدِ"/>
                <CardAja klik={() => ClickKalimat("/bab-badal")} gambar={abstrak10} p="Jika isim diganti dengan isim yang lainnya, atau fi'il dengan fi'il yang lainnya" h2="بَابُا البَدَلِ"/>
            </div>
        </div>
        </>
    )
} 
    return(
        <>
            <NavSlip/>
            <Navbar/>
            <Halaman/>
            <Halaman1/>
            <Halaman2/>
            <Bawah/>
        </>
    )

}


