import  {useState,useEffect,useRef}  from "react";
import '../css/navbarfile.css'
import { useNavigate } from "react-router-dom";
import { findPathByQuery } from "./search";

export default function NavbarFile() {
    const [scrolled, setScrolled] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
    const [searchQuery, setSearchQuery] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [searchHistory, setSearchHistory] = useState(() => JSON.parse(localStorage.getItem('searchHistory') || '[]'));
    const [showHistory, setShowHistory] = useState(false);
    const [suggestions, setSuggestions] = useState([]);


    const navigate = useNavigate();
    const navimgBbRef = useRef(null);

    useEffect(() => {
        if(searchQuery.trim() !== "") {
            const filteredSuggestions = searchHistory.filter(item =>
                item.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    }, [searchQuery, searchHistory]);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 25);
            setShowHistory(false);
        };
        window.addEventListener('scroll', handleScroll);

        const handleClickOutside = (event) => {
            if (navimgBbRef.current && !navimgBbRef.current.contains(event.target)) {
                setShowHistory(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        document.body.classList.toggle('dark-mode', isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        const newIsDarkMode = !isDarkMode;
        localStorage.setItem('darkMode', String(newIsDarkMode));
        setIsDarkMode(newIsDarkMode);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const path = findPathByQuery(searchQuery);
        if (path) {
            navigate(path);
            addSearchQueryToHistory(searchQuery);
        } else {
            setNotificationMessage(`Tidak dapat menemukan hasil untuk : ${searchQuery}`);
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 5000);
        }
    };

    const addSearchQueryToHistory = (query) => {
        const updatedHistory = [...new Set([query, ...searchHistory])].slice(0, 10);
        setSearchHistory(updatedHistory);
        localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
    };

    const handleDeleteHistory = (index) => {
        const newHistory = searchHistory.filter((_, i) => i !== index);
        setSearchHistory(newHistory);
        localStorage.setItem('searchHistory', JSON.stringify(newHistory));
    };

    const handleHistoryClick = (item) => {
        const path = findPathByQuery(item);
        if (path) {
            navigate(path);
        }
    };

        return(
            <>
            {showNotification && (
                <div className="notification">
                  {notificationMessage}
                </div>
            )}
            <div className='navbar' >
                <div className={`navbarAll ${scrolled  ? 'scrolled' : ''}`}>
                    <div className="navImg">
                        <div className="navimgAa">
                            <label className="toggle" htmlFor="switch">
                                <input 
                                id="switch" 
                                className="input" 
                                type="checkbox"
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                />
                                <div className="icon icon--moon">
                                    <svg height="32" width="32" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path clipRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" fillRule="evenodd"></path>
                                    </svg>
                                </div>
                                <div className="icon icon--sun">
                                    <svg height="32" width="32" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"></path>
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <div className="navimgBb" ref={navimgBbRef}>
                            <form onSubmit={handleSearchSubmit}>
                            <input type="text" 
                            placeholder="cari"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onFocus={() => setShowHistory(true)}
                            />
                            </form>
                            {showHistory && (
                                <div className="searchHistory">
                                    {suggestions.map((item, index) => (
                                        <div key={index} className="searchHistoryItem">
                                            <span className="span1" onClick={() =>{setSearchQuery(item); handleHistoryClick(item); setShowHistory(false);}}>{item}</span>
                                            <span className="span2" onClick={() => handleDeleteHistory(index)}><i class="bi bi-x"></i></span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='navbarFull'>
                        <div className="navbarFullan" onClick={()=>navigate("/")}>
                            <h4>halaman awal</h4>
                            <i className="bi bi-arrow-right-short"></i>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
}
