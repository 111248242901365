import '../css/bawah.css'
import { useNavigate } from 'react-router-dom'

function NAvbarName(){
    const navigate = useNavigate();
    const openClick = (e) => {
      if (e.target.tagName === 'P') {
        const destination = e.target.getAttribute('data-url');
        const actionType = e.target.getAttribute('data-type'); 
        if (destination) {
          if (actionType === 'external') {
            window.open(destination, '_blank');
          } else {
            navigate(destination);
          }
        }
      }
    };
    return(
        <>
        <div className='navbarslip2'>
        <div onClick={openClick}>
            <h1>permulaan</h1>
            <p data-url="/bab-kalam">kalam</p>
            <p data-url="/bab-ikrob">ikrob</p>
            <p data-url="/bab-makrifat">makrifat</p>
            <p data-url="/faslun">faslun</p>
            <p data-url="/bab-afal">af'al</p>
        </div>
        <div onClick={openClick}>
            <h1>mengikut</h1>
            <p data-url="/bab-naat">na'at</p>
            <p data-url="/bab-atof">atof</p>
            <p data-url="/bab-taukid">taukit</p>
            <p data-url="/bab-badal">badal</p>
        </div>
        <div onClick={openClick}>
            <h1>isim-isim yang di rofa'kan</h1>
            <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
            <p data-url="/bab-fail">fa'il</p>
            <p data-url="/bab-naibulfail">naibul fa'il</p>
            <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
            <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
        </div>
        <div onClick={openClick}>
            <h1>isim-isim yang di nasabkan</h1>
            <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
            <p data-url="/bab-mafulunbih">mafulunbih</p>
            <p data-url="/bab-masdar">masdar</p>
            <p data-url="/bab-zorofzaman-wamakan">zorof</p>
            <p data-url="/bab-hal">hal</p>
            <p data-url="/bab-tamyiz">tamyiz</p>
            <p data-url="/bab-istisna">istisna</p>
            <p data-url="/bab-laa">laa</p>
            <p data-url="/bab-munada">munada</p>
            <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
            <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
        </div>
        <div onClick={openClick}>
            <h1>isim-isim yang di khofadkan</h1>
            <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
        </div>
        <div onClick={openClick}>
            <h1>traktir</h1>
            <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
        </div>
    </div>
        </>
    )
}

export default function Bawah(){
    const navigate = useNavigate();
    const openClick = (e) => {
        if (e.target.tagName === 'BUTTON') {
          const destination = e.target.getAttribute('data-url');
          const actionType = e.target.getAttribute('data-type'); 
          if (destination) {
            if (actionType === 'external') {
              window.open(destination, '_blank');
            } else {
              navigate(destination);
            }
          }
        }}
        const openClickkk = (e) => {
            if (e.target.tagName === 'I') {
              const destination = e.target.getAttribute('data-url');
              const actionType = e.target.getAttribute('data-type'); 
              if (destination) {
                if (actionType === 'external') {
                  window.open(destination, '_blank');
                } else {
                  navigate(destination);
                }
              }
            }}
    return(
        <>
        <div className='bawah'>
            <div className='bawahAja'>
                <div className='bawahImg' onClick={openClick}>
                    <h1>bantu creator</h1>
                    <h1>dengan cara suppord</h1>
                    <button data-url="https://saweria.co/Khaizulaftar" data-type="external"><i className="bi bi-arrow-right-short"></i>donate</button>
                    <p>tempat belajar ilmu nahwu secara singkat dan tepat dengan berpedoman kan kitab aljurumiyah</p>
                </div>
                <div className='bawahBab'>
                    <NAvbarName/>
                </div>
            </div>
            <div className='bawahAsa'>
                <div className='ini' onClick={openClickkk}>
                    <div>
                        <p>infoin<span>id</span>.com</p>
                    </div>
                    <i class="bi bi-whatsapp" data-url="https://wa.me/+6281370397395" data-type="external"></i>
                    <i class="bi bi-envelope" data-url="mailto:khaizulaftar150704@gmail.com" data-type="external"></i>
                    <i class="bi bi-instagram" data-url="https://instagram.com/khais1507" data-type="external"></i>
                </div>
                <p>&copy;infoinid mai 2024</p>
            </div>
        </div>
        </>
    )
}