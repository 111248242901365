import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import naibul1 from '../sourc/naibul zohir.png'
import naibul2 from '../sourc/naibul mudmar.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab naibul fail</title>
              <meta name="description" content="Yaitu isim yang di rofa`kan yang tidak disebutkan fa`il (pelaku) nya" />
        </Helmet>
        <h1 className='h1'>بَابُ المَفْعُوْلِ الَّذِي لَمْ يُسَمَّ فَاعِلُهُ</h1>
        <h2>وَهُوَ : الاِسْمُ المَرْفُوْعُ الَّذِي لَمْ يُذْكَرْ مَعَهُ فَاعِلُهُ</h2>
        <p>Yaitu isim yang di rofa`kan yang tidak disebutkan fa`il (pelaku) nya</p>
        <h2>فَإِنْ كَانَ الفِعْلُ مَاضِيًا: ضُمَّ أَوَّلُهُ وَكُسِرَ مَا قَبْلَ آخِرِهِ، وَإِنْ كَانَ مُضَارِعًا: ضُمَّ أَوَّلُهُ وَفُتِحَ مَا قَبْلَ آخِرِهِ</h2>
        <p>Apabila fi`il nya berbentuk fi`il madhi di dhammah kan huruf awalnya, dan di kasroh kan huruf sebelum akhir. Akan tetapi, jika berbentuk fi`il mudhari` di dhammah kan huruf awalnya, dan di fathah kan huruf sebelum akhir</p>
        <h2>وَهُوَ قِسْمَيْنِ : ظَاهِرٍ، وَمُضْمَرٍ</h2>
        <p>Dan dia terbagi menjadi 2 macam yaitu: isim zhohir dan isim dhomir</p>
        <h2>فَالظَّاهِرُ؛ نَحْوُ قَوْلِكَ: (ضُرِبَ زَيْدٌ)، وَ(يُضْرَبُ زَيْدٌ)، وَ (أُكْرِمَ عَمْرٌو)، وَ(يُكْرَمُ عَمْرٌو)</h2>
        <p>Adapun yang berbentuk isim zhahir seperti contohnya: (ضُرِبَ زَيْدٌ)، وَ(يُضْرَبُ زَيْدٌ)، وَ (أُكْرِمَ عَمْرٌو)، وَ(يُكْرَمُ عَمْرٌو)</p>
        <img src={naibul1} alt='gambar'></img>
        <h2>وَالمُضْمَرُ اثْنَا عَشَرَ؛ نَحْوُ قَوْلِكَ: (ضُرِبْتُ)، وَ(ضُرِبْنَا)، وَ(ضُرِبْتَ)، وَ(ضُرِبْتِ)، وَ(ضُرِبْتُمَا)، وَ(ضُرِبْتُمْ)، وَ(ضُرِبْتُنَّ)، وَ(ضُرِبَ)، وَ(ضُرِبَتْ)، وَ(ضُرِبَا)، وَ(ضُرِبُوْا)، وَ(ضُرِبْنَ)</h2>
        <p>Sedangkan yang berbentuk isim dhamir ada 12 contohnya: (ضُرِبْتُ)، وَ(ضُرِبْنَا)، وَ(ضُرِبْتَ)، وَ(ضُرِبْتِ)، وَ(ضُرِبْتُمَا)، وَ(ضُرِبْتُمْ)، وَ(ضُرِبْتُنَّ)، وَ(ضُرِبَ)، وَ(ضُرِبَتْ)، وَ(ضُرِبَا)، وَ(ضُرِبُوْا)، وَ(ضُرِبْنَ)</p>
        <img src={naibul2} alt='gambar'></img>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-fail"></p>
          <h4>bab fail</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab maf`ul allazi lam yusamma fa`iluhu</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab mubtadak wa khobar</h4>
          <p className="bi bi-chevron-right" data-url="/bab-mubtadak-khobar"></p>
        </div>
      </div>
    </div>
  );
}

export default function NaibulFail(){
    return(
        <>        
        <NavbarFile/>
        <Halaman/>
        </>
    )
}