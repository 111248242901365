import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import kana from '../sourc/kana.png'
import inna from '../sourc/inna.png'
import zonna from '../sourc/zonna.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab amil yang masuk kepada mubtadak dan khobar</title>
              <meta name="description" content="Amil-amil yang masuk kepada mubtada` dan khabar ada 3 jenis yaitu:1 (كَانَ) dan saudara-saudaranya, 2 (إِنَّ) dan saudara-saudaranya, 3 (ظَنَنْتُ) dan saudara-saudaranya" />
        </Helmet>
        <h1 className='h1'>بَابُ العَوَامِلِ الدَّاخِلَةِ عَلَى المُبْتَدَأِ وَالخَبَرِ</h1>
        <h2>وَهِيَ ثَلَاثَةُ أَشْيَاءَ: كَانَ وَأَخَوَاتُهَا، وَإِنَّ وَأَخَوَاتُهَا، وَظَنَنْتُ وَأَخَوَاتُهَا</h2>
        <p>Amil-amil yang masuk kepada mubtada` dan khabar ada 3 jenis yaitu:1 (كَانَ) dan saudara-saudaranya, 2 (إِنَّ) dan saudara-saudaranya, 3 (ظَنَنْتُ) dan saudara-saudaranya</p>
        <h2>فَأَمَّا كَانَ وَأَخَوَاتُهَا: فَإِنَّهَا تَرْفَعُ الْاِسْمَ، وَتَنْصِبُ الخَبَرَ</h2>
        <p>Adapun (كَانَ) dan saudara-saudaranya berfungsi merafa`kan isim dan menasab kan khabar</p>
        <h2>وَهِيَ: كاَنَ، وَأَمْسَى، وَأَصْبَحَ، وَأَضْحَى، وَظَلَّ ، وَبَاتَ، وَصَارَ، وَلَيْسَ، وَمَا زَالَ، وَمَا اِنْفَكَّ، وَمَا فَتِيءَ، وَمَا بَرِحَ، وَمَا دَامَ، وَمَا تَصَرَّفَ مِنْهَا، نَحْو:ُ كَانَ وَيَكُوْنُ وَكُنْ،وَأَصْبَحَ وَيُصْبِحُ وَأَصْبِحْ، تَقُوْلُ: كَانَ زَيْدٌ قَائِمًاً، وَلَيْسَ عَمْرٌو شَاخِصًاً، وَمَا أَشْبَهَ ذَلِكَ</h2>
        <p>saudara-saudaranya kana yaitu: كَانَ - أَمْسَى - أَصْبَحَ - أَضْحَى - ظَلَّ - بَاتَ - صَارَ - لَيْسَ - مَا زَالَ - مَا انْفَكَّ - مَا فَتِئَ - مَا بَرِحَ - مَا دَامَ - وَمَا تَصَرَّفَ مِنْهَا kamu katakan : كَانَ زَيْدٌ قَائِمًاً، وَلَيْسَ عَمْرٌو شَاخِصً dan umpama yang lain nya</p>
        <img src={kana} alt='contoh kana'></img>
        <h2>وَأَمَّا إِنَّ وَأَخَوَاتُهَا: فَإِنَّهَا تَنْصِبُ الْاِسْمَ وَتَرْفَعُ الخَبَرَ</h2>
        <p>Sedangkan (إِنَّ) dan saudara-saudaranya berfungsi me nasab kan isim dan me rafa` kan khabar</p>
        <h2>وَهِيَ (إِنَّ)، وَ(أَنَّ)، وَ(لَكِنَّ)، وَ(كَأَنَّ)، وَ(لَيْتَ)، وَ(لَعَلَّ)</h2>
        <p>inna dan saudara-saudara nya : إِنَّ - أَنَّ - لَكِنَّ - كَأَنَّ - لَيْتَ - لَعَلَّ</p>
        <h2>تَقُوْلُ : (إِنَّ زَيْدًا قَائِمٌ)، وَ(لَيْتَ عَمْرًا شَاخِصٌ)</h2>
        <p>kamu katakan : (إِنَّ زَيْدًا قَائِمٌ : Sesungguhnya Zaid telah berdiri), (لَيْتَ عَمْرًا شَاخِصٌ : Sekiranya Amr pergi)</p>
        <img src={inna} alt='contoh inna'></img>
        <h2>وَمَعْنَى (إِنَّ) وَ(أَنَّ) لِلتَّوْكِيْدِ، وَ(لَكِنَّ) لِلاِسْتِدْرَاكِ، وَ(كَأَنَّ) لِلتَّشْبِيْهِ، وَ(لَيْتَ) لِلتَّمَنِّي، وَ(لَعَلَّ) لِلتَّرَجِّي وَالتّوَقُّعِ</h2>
        <p>(إِنَّ) dan (أَنَّ) adalah untuk memperkuat ucapan, (لَكِنَّ) untuk memutarbalikan fakta, (كَأَنَّ) untuk menyatakan adanya persamaan, (لَيْتَ) menyatakan angan-angan, (لَعَلَّ) menyatakan pengharapan</p>
        <h2>وَأَمَّا ظَنَنْتُ وَأَخَوَاتُهَا: فَإِنَّهَا تَنْصِبُ المُبْتَدَأَ وَالخَبَرَ عَلَى أَنَّهُمَا مَفْعُوْلَانِ لَهَا</h2>
        <p>adapun (ظَنَنْتُ) dan saudara-saudaranya, berfungsi me nashb kan mubtada` dan khabar sekaligus keduanya menjadi maf`ul baginya</p>
        <h2>وَهِيَ : (ظَنَنْتُ)، وَ(حَسِبْتُ)، وَ(خِلْتُ)، وَ(زَعَمْتُ)، وَ(رَأَيْتُ)، وَ(عَلِمْتُ)، وَ(وَجَدْتُ)، وَ(اتَّخَذْتُ)، وَ(جَعَلْتُ) وَ(سَمِعْتُ)</h2>
        <p>zonna dan saudara-saudaranya: ظَنَنْتُ - حَسِبْتُ - خِلْتُ - زَعَمْتُ - رَأَيْتُ - عَلِمْتُ - وَجَدْتُ - اتَّخَذْتُ - جَعَلْتُ - سَمِعْتُ</p>
        <h2>تَقُوْلُ : (ظَنَنْتُ زَيْداً قَائِمًا)، وَ(رَأَيْتُ عَمْراً شَاخِصًا)، وَمَا أَشْبَهَ ذَلِكَ</h2>
        <p>kamu katakan: ظَنَنْتُ زَيْداً قَائِمًا : Aku mengira Zaid berdiri, رَأَيْتُ عَمْراً شَاخِصًا : Aku melihat Amr pergi, dan semisal nya</p>
        <img src={zonna} alt='contoh zonna'></img>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-mubtadak-khobar"></p>
          <h4>bab mubtadak wa khobar</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab awamid dhakhilati alal mubtadak wa khobar</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab mansubatil asma`</h4>
          <p className="bi bi-chevron-right" data-url="/bab-mansubatil-asma"></p>
        </div>
      </div>
    </div>
  );
}

export default function AmilNAwasikh(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}