import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab badal</title>
              <meta name="description" content="Jika isim diganti dengan isim yang lainnya, atau fi`il dengan fi`il yang lainnya, maka ia mengikuti isim yang diganti dalam semua i`rob-nya" />
        </Helmet>
        <h1 className='h1'>بَابُ البَدَلِ</h1>
        <h2>إِذَا أُبْدِلَ اِسْمٌ مِنِ اسْمٍ أَوْ فِعْلٌ مِنْ فِعْلٍ تَبِعَهُ فِي جَمِيعِ إِعْرَابِهِ</h2>
        <p>Jika isim diganti dengan isim yang lainnya, atau fi`il dengan fi`il yang lainnya, maka ia mengikuti isim yang diganti dalam semua i`rob-nya</p>
        <h2>وَهُوَ عَلَى أَرْبَعَةِ أَقْسَامٍ: بَدَلُ اَلشَّيْءِ مِنَ الشَّيْءِ، وَبَدَلُ البَعْضِ مِنَ الكُلِّ، وَبَدَلُ الِاشْتِمَالِ، وَبَدَلُ الغَلَطِ</h2>
        <p>Badal dibagi menjadi 4 bagian : بَدَلُ اَلشَّيْءِ مِنْ اَلشَّيْء - بَدَلُ البَعْضِ مِنْ الكُلِّ - بَدَلُ الاِشْتِمَالِ - بَدَلُ الغَلَطِ</p>
        <h2>نَحْوَ قَوْلِكَ: (قَامَ زَيْدٌ أَخُوكَ)، وَ(أَكَلْتُ اَلرَّغِيفَ ثُلُثَهُ)، وَ(نَفَعَنِي زَيْدٌ عِلْمُهُ)، وَ(رَأَيْتُ زَيْدًا الفَرَسَ)، أَرَدْتَ أَنْ تَقُولَ: رَأَيْتُ الفَرَسَ؛ فَغَلِطْتَ، فَأَبْدَلْتَ زَيْدًا مِنْهُ</h2>
        <p>contoh perkataan mu: قَامَ زَيْدٌ أَخُوْكَ (telah bangun Zaid, saudaramu), أَكَلْتُ الرَّغِيْفَ ثُلُثَهُ (aku makan roti sepertiganya), نَفَعَنِيْ زَيْدٌ عِلْمُهُ (bermanfaat untukku zaid ilmunya), رَأَيْتُ زَيْدًا الفَرَسَ (aku melihat zaid eh.. maksudku kuda)</p>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-taukid"></p>
          <h4>bab taukid</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab badal</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab kalam</h4>
          <p className="bi bi-chevron-right" data-url="/bab-kalam"></p>
        </div>
      </div>
    </div>
  );
}

export default function Badal(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}