import  {useState,useEffect}  from "react";
import '../css/navbar.css'
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

export default function Navbar() {
    // style ketika di scroll
    const [scrolled, setScrolled] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true'); 

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 25);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // use nafiget
    let navigate = useNavigate();

    useEffect(() => {
        document.body.classList.toggle('dark-mode', isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        const newIsDarkMode = !isDarkMode;
        localStorage.setItem('darkMode', String(newIsDarkMode));
        setIsDarkMode(newIsDarkMode);
    };

    const openClick = (e) => {
      if (e.target.tagName === 'P') {
        const destination = e.target.getAttribute('data-url');
        const actionType = e.target.getAttribute('data-type'); 
        if (destination) {
          if (actionType === 'external') {
            window.open(destination, '_blank');
          } else {
            navigate(destination);
          }
        }
      }
    };

        return(
            <>
            <div className={`navbarrr ${scrolled  ? 'scrolled' : 'scrolan'}`}>
                <div className='navbarAlll'>
                    <div className="navImg">
                        <div className="navimgAa">
                            <label className="toggle" htmlFor="switch">
                                <input 
                                id="switch" 
                                className="input" 
                                type="checkbox"
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                />
                                <div className="icon icon--moon">
                                    <svg height="32" width="32" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path clipRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" fillRule="evenodd"></path>
                                    </svg>
                                </div>
                                <div className="icon icon--sun">
                                    <svg height="32" width="32" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"></path>
                                    </svg>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className='navbarFulll' onClick={openClick}>
                        <p data-url="/">home</p>
                        <p data-url="https://wa.me/+6281370397395" data-type="external">contact</p>
                        <p data-url="https://saweria.co/Khaizulaftar" data-type="external">traktir</p>
                    </div>
                </div>
            </div>
            </>
        )
}
