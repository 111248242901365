import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab af`al</title>
              <meta name="description" content="Fi`il terbagi menjadi 3 : 1 Fi`il Madhi, 2 Fi`il Mudhari`, 3 Fi`il Amr" />
        </Helmet>
        <h1 className='h1'>بَابُ اﻷَفْعَالِ</h1>
        <h2>الأَفْعَالُ ثَلَاثَةٌ : مَاضٍ، وَمُضَارِعٌ، وَأَمْرٌ؛ نَحْوُ : (ضَرَبَ)، وَ(يَضْرِبُ)، وَ(اضْرِبْ)</h2>
        <p>Fi`il terbagi menjadi 3 : 1 Fi`il Madhi, 2 Fi`il Mudhari`, 3 Fi`il Amr</p>
        <h2>فَالمَاضِيْ: مَفْتُوْحُ الآخِرِ أَبَدًا</h2>
        <p>maka fiil madhi di fathah kan selama nya </p>
        <h2>وَالأَمْرُ: مَجْزُوْمٌ أَبَدًا</h2>
        <p>sedangkan Fi`il Amr di jazam kan selama nya</p>
        <h2>وَالمُضَارِعُ: مَا كَانَ فِي أَوَّلِهِ إِحْدَى الزَّوَائِدِ الأَرْبَعِ؛ الَّتِي يَجْمَعُهَا قَوْلُكَ: (أَنَيْتُ)، وَهُوَ مَرْفُوْعٌ أَبَدًا حَتَّى يَدْخُلَ عَلَيْهِ نَاصِبٌ أَوْ جَازِمٌ</h2>
        <p>Fi`il Mudhari` adalah fi`il yang di awalnya terdapat salah satu dari empat huruf tambahan yang terkumpul dalam kata (أَنَيْتُ). Dan fi`il mudhari` selalu di rofa`kan sampai masuk kepadanya amil nashob atau amil jazm</p>
        <h2>فَالنَّوَاصِبُ عَشَرَةٌ؛ وَهِيَ : (أَنْ)، وَ(لَنْ)، وَ(إِذَنْ)، وَ(كَيْ)، وَ(لَامُ كَيْ)، وَ(لَامُ الجُحُوْدِ)، وَ(حَتَّى)، وَ الجَوَابُ بـ(الفَاءِ)، و(الوَاوِ)، وَ(أَوْ)</h2>
        <p>yang menashobkan fi`il mudhari` ada 10 di antaranya: (أَوْ)- أَنْ - لَنْ - إِذَنْ - كَيْ - لَامُ كَيْ - لَامُ الجُحُوْدِ - حَتَّى - الجَوَابُ بـ(الفَاءِ) - الجَوَابُ بـ(الوَاوِ)</p>
        <h2>وَالجَوَازِمُ ثَمَانِيَةَ عَشَرَ؛ وَهِيَ : (لَمْ)، وَ(لَمَّا)، وَ(أَلَمْ)، وَ(أَلَمَّا)، وَ(لَامُ الأَمْرِ وَالدُّعَاءِ)، وَ(لَا) فِي النَّهْيِ وَالدُّعَاءِ، وَ(إِنْ)، وَ(مَا)، (مَنْ), وَ(مَهْمَا)، وَ(إِذْمَا)، وَ(أَيْ)، وَ(مَتَى)، وَ(أَيْنَ)، وَ(أَيَّانَ)، وَ(أَنَّى)، وَ(حَيْثُمَا)، وَ(كَيْفَمَا)، وَ(إذَا) فِي الشِّعْرِ خَاصَّةً</h2>
        <p>yang men-jazm-kan fi`il mudhari` yaitu:لَمْ - لَمَّا - أَلَمْ - أَلَمَّا - لَامُ الأََمْرِ وَالدُّعَاءِ - لَا فِي النَّهْيِ وَالدُّعَاءِ - إِنْ - مَا - مَنْ - مَهْمَا - إِذْمَا - أَيٌّ - مَتَى - أَيَّانَ - أَيْنَ -أَنَّى - حَيْثُمَا - كَيْفَمَا - إِذَا فِي الشِّعْرِ خَاصَةً</p>
        <div className='ket'>
          <h3>keterangan</h3> 
          <ul>
            <li>fiil madhi menunjukan masa yang telah lewat (masa lalu)</li>
            <li>fiil mudhorik menunjukan masa sekarang atau akan datang</li>
            <li>fiil amar menunjukan perintah atau masa akan datang</li>
          </ul>
        </div>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/faslun"></p>
          <h4>faslun</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab af`al</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab marfuatil asma`</h4>
          <p className="bi bi-chevron-right" data-url="/bab-marfuatil-asma"></p>
        </div>
      </div>
    </div>
  );
}

export default function Afal(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}