import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import ikrobharkat from '../sourc/ikrob harkat.png'
import ikrobhuruf from '../sourc/ikrob huruf.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>faslun</title>
              <meta name="description" content="yang di i`rob ada 2 macam yaitu: 1. Jenis yang di i`rob dengan harokat, 2. Jenis yang di i`rob dengan huruf" />
        </Helmet>
        <h1 className='h1'>فَصْلٌ</h1>
        <h2>المُعْرَبَاتُ قِسْمَانِ : قِسْمٌ يُعْرَبُ بِالحَرَكَاتِ، وَقِسْمٌ يُعْرَبُ بِالحُرُوْفِ</h2>
        <p>yang di i`rob ada 2 macam yaitu: 1. Jenis yang di i`rob dengan harokat, 2. Jenis yang di i`rob dengan huruf</p>
        <h2>فَالَّذِي يُعْرَبُ بِالحَرَكَاتِ أَرْبَعَةُ أَنْوَاعٍ : الاِسْمُ المُفْرَدُ، وَجَمْعُ التَّكْسِيْرِ، وَجَمْعُ المُؤَنَّثِ السَّالِمُ، وَالفِعْلُ المُضَارِعُ الَّذِي لَمْ يَتَّصِلْ بِآخِرِهِ شَيْءٌ</h2>
        <p>Adapun yang di i`rob dengan harokat terdiri dari 4 macam : Isim Mufrod - Jamak Taksir - Jamak Muannats Salim - Fi`il Mudhari` yang akhirannya tidak bersambung dengan sesuatu pun</p>
        <h2>وَكُلُّهَا تُرْفَعُ بِالضَّمَّةِ، وَتُنْصَبُ بِالفَتْحَةِ، وَتُخْفَضُ بِالكَسْرَةِ، وَتُجْزَمُ بِالسُّكُوْنِ</h2>
        <p>Seluruhnya di rofa`kan dengan dommah, di nashob kan dengan fathah, di khofdh kan dengan kasroh, dan di jazm kan dengan sukun</p>
        <h2>وَخَرَجَ عَنْ ذَلِكَ ثَلَاثَةُ أَشْيَاءَ : جَمْعُ المُؤَنَّثِ السَّالِمُ يُنْصَبُ بِالكَسْرَةِ، وَالاِسْمُ الَّذِي لَا يَنْصَرِفُ يُخْفَضُ بِالفَتْحَةِ، وَالفِعْلُ المُضَارِعُ المُعْتَلُّ الآخِرُ يُجْزَمُ بِحَذْفِ آخِرِهِ</h2>
        <p>Kecuali dalam tiga hal yaitu: Jamak Muannats Salim di nashobkan dengan kasroh - Isim yang tidak bertanwin di khofad kan dengan fathah - Fi`il Mudhari` Mu`tal Akhir di jazm kan dengan membuang huruf akhirnya</p>
        <img src={ikrobharkat} alt='contoh yang di ikrob dengan harkat'></img>
        <h2>وَالَّذِي يُعْرَبُ بِالحُرُوْفِ أَرْبَعَةُ أَنْوَاعٍ : التَّثْنِيَةُ، وَجَمْعُ المُذَكَّرِ السَّالِمُ، وَالأَسْمَاءُ الخَمْسَةُ، وَالأَفْعَالُ الخَمْسَةُ، وَهِيَ : يَفْعَلَانِ، وَتَفْعَلَانِ، وَيَفْعَلُوْنَ، وَتَفْعَلُوْنَ، وَتَفْعَلِيْنَ</h2>
        <p>Adapun yang di i`rob kan dengan huruf ada 4 macam sebagai: Tasniyah - Jamak Mudzakkar Salim - Asmaul Khamsah - Af`alul Khamsah</p>
        <h2>فَأَمَّا التَّثْنِيَةُ فَتُرْفَعُ بِالأَلِفِ، وَتُنْصَبُ وَتُخْفَضُ بِاليَاءِ</h2>
        <p>Adapun tasniyah rofa` dengan alif, nashob dan khofadh dengan yaa</p>
        <h2>وَأَمَّا جَمْعُ المُذَكَّرِ السَّالِمُ فَيُرْفَعُ بِالوَاوِ، وَيُنْصَبُ وَيُخْفَضُ بِاليَاءِ</h2>
        <p>dapun jamak mudzakar salim rofa` dengan wawu, nashob dan khofadh dengan yaa</p>
        <h2>وَأَمَّا الأَسْمَاءُ الخَمْسَةُ فَتُرْفَعُ بِالوَاوِ، وَتُنْصَبُ بِالأَلِفِ وَتُخْفَضُ بِاليَاءِ</h2>
        <p>Adapun asmaul khomsah (isim yang 5) rofa` dengan wawu, nashob dengan alif, dan khofadh dengan yaa</p>
        <h2>وَأَمَّا الأَفْعَالُ الخَمْسَةُ فَتُرْفَعُ بالنون وتُنصَبُ وَتُجْزَمُ بِحَذْفِهَا</h2>
        <p>Adapun af`alul khomsah (fi`il yang 5) rofa` dengan nun dan jazm dengan menghilangkan huruf nun tersebut</p>
        <img src={ikrobhuruf} alt='contoh yang di ikrob dengan huruf'></img>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-makrifat"></p>
          <h4>bab makrifat</h4>
        </div>
        <div className='containesliper'> 
          <h3>faslun</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab af`al</h4>
          <p className="bi bi-chevron-right" data-url="/bab-afal"></p>
        </div>
      </div>
    </div>
  );
}

export default function Faslun(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}