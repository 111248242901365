import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import bilmudof from '../sourc/bilmudof.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab makhfudotil asma`</title>
              <meta name="description" content="yang di khofadkan itu ada 3 macam: مَخْفُوضٌ بِالحَرْفِ، وَمَخْفُوضٌ بِالإِضَافَةِ، وَتَابِعٌ لِلمَخْفُوضِ" />
        </Helmet>
        <h1 className='h1'>بَابُ مَخْفُوْضَاتِ الأَسْمَاءِ</h1>
        <h2>المَخْفُوضَاتُ ثَلَاثَةُ أَنْوَاعٍ: مَخْفُوضٌ بِالحَرْفِ، وَمَخْفُوضٌ بِالإِضَافَةِ، وَتَابِعٌ لِلمَخْفُوضِ</h2>
        <p>yang di khofadkan itu ada 3 macam: مَخْفُوضٌ بِالحَرْفِ، وَمَخْفُوضٌ بِالإِضَافَةِ، وَتَابِعٌ لِلمَخْفُوضِ</p>
        <h2>فَأَمَّا المَخْفُوضُ بِالحَرْفِ فَهُوَ مَا يَخْتَصُّ بِـ(مِنْ)، وَ(إِلَى)، وَ(عَنْ)، وَ(عَلَى)، وَ(فِي)، وَ(رُبَّ)، وَ(الْبَاءِ)، وَ(الْكَافِ)، وَ(اللَّامِ)، وَبِحُرُوفِ القَسَمِ – وَهِيَ: (الوَاوُ)، وَ(الْبَاءُ)، وَ(التَّاءُ)، وَبِـ(وَاوِ رُبَّ)، وَبِـ(مُذْ)، وَ(مُنْذُ)</h2>
        <p>yang di khofadkan dengan huruf, yaitu: isim yang dibaca khafdh dengan: min, ilaa, `an, `alaa, fiy, rubba, al-baa-i, al-kaafi, al-laami, dan juga dengan huruf sumpah: al-waawu, al-baa-u, at-taa-u. Atau dengan wawu yang semakna dengan rubbah, mudz, dan mundzu</p>
        <h2>وَأَمَّا مَا يُخْفَضُ بِالْإِضَافَةِ؛ فَنَحْوُ قَوْلِكَ: (غُلَامُ زَيْدٍْ)</h2>
        <p>Sedangkan yang dibaca khafdh karena idhafah, contoh nya: غُلَامُ زَيْدٍ(pembantu si Zaid)</p>
        <img src={bilmudof} alt='contoh munada'></img>
        <h2>وَهُوَ عَلَى قِسْمَيْنِ: مَا يُقَدَّرُ بِـ(اللَّامِ)، وَمَا يُقَدَّرُ بِـ(مِنْ)</h2>
        <p>Isim yang di khafdh karena idhafah ada 2 macam: Yang diperkirakan dengan makna (اللام) = milik - dan diperkirakan dengan makna (من) = dari</p>
        <h2>فَالَّذِي يُقَدَّرُ بِـ(اللَّامِ)؛ نَحْوُ قَوْلِكَ: (غُلَامُ زَيْدٍ)</h2>
        <p>yang diperkirakan dengan makna (اللام) = milik, contohnya: غُلَامُ زَيْدٍ(Pelayan milik Zaid)</p>
        <h2>وَالَّذِي يُقَدَّرُ بِـ(مِنْ)؛ نَحْوُ قَوْلِكَ: (ثَوْبُ خَزٍّ)، وَ(بَابُ سَاجٍ)، وَ(خَاتَمُ حَدِيدٍ)</h2>
        <p>Yang diperkirakan dengan makna (من) = dari, contohnya: ثَوْبُ خَزٍّ(Baju dari sutera), بَابُ سَاجٍ(Pintu dari Jati), خَاتَمُ حَدِيدٍ(Cincin dari besi)</p>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-mafulmaah"></p>
          <h4>bab maf`ul maah</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab makhfudotil asma`</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab na`at</h4>
          <p className="bi bi-chevron-right" data-url="/bab-naat"></p>
        </div>
      </div>
    </div>
  );
}

export default function MAkhfudot(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}