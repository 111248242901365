function levenshteinDistance(s1, s2) {
  if (s1.length === 0) return s2.length;
  if (s2.length === 0) return s1.length;

  const matrix = [];

  let i;
  for (i = 0; i <= s2.length; i++) {
    matrix[i] = [i];
  }


  let j;
  for (j = 0; j <= s1.length; j++) {
    matrix[0][j] = j;
  }

  for (i = 1; i <= s2.length; i++) {
    for (j = 1; j <= s1.length; j++) {
      if (s2.charAt(i - 1) === s1.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                                Math.min(matrix[i][j - 1] + 1, // insertion
                                         matrix[i - 1][j] + 1)); // deletion
      }
    }
  }

  return matrix[s2.length][s1.length];
}

export const findPathByQuery = (query) => {
  const queryNormalized = query.toLowerCase();

  const keywordGroups = [
    { keywords: ["kalam", "bab kalam"], path: "/bab-kalam" },
    { keywords: ["ikrob", "bab ikrob"], path: "/bab-ikrob" },
    { keywords: ["makrifat", "bab makrifat"], path: "/bab-makrifat" },
    { keywords: ["faslun", "bab faslun"], path: "/faslun" },
    { keywords: ["afal", "bab afal"], path: "/bab-afal" },
    { keywords: ["marfuatil asma"], path: "/bab-kalam" },
    { keywords: ["fail", "bab fail"], path: "/bab-fail" },
    { keywords: ["naibulfail", "bab naibulfail", "maful", "bab maful", "mafulullazi lam yusama failuhu"], path: "/bab-naibulfail" },
    { keywords: ["mubtadak khobar", "bab mubtadak khobar"], path: "/bab-mubtadak-khobar" },
    { keywords: ["awamilid dakhilati alal mubtadak wa khobar", "bab awamilid dakhilati alal mubtadak wa khobar"], path: "/bab-awamilidakhilatialalmubtadakwakhobar" },
    { keywords: ["mansubatil asma", "bab mansubatil asma"], path: "/bab-mansubatil-asma" },
    { keywords: ["mafulunbih", "bab mafulunbih"], path: "/bab-mafulunbih" },
    { keywords: ["zorofzaman wa makan", "bab zorofzaman wa makan"], path: "/bab-zorofzaman-wamakan" },
    { keywords: ["hal", "bab hal"], path: "/bab-hal" },
    { keywords: ["tamyiz", "bab tamyiz"], path: "/bab-tamyiz" },
    { keywords: ["istisna", "bab istisna"], path: "/bab-istisna" },
    { keywords: ["laa", "bab laa"], path: "/bab-laa" },
    { keywords: ["munada", "bab munada"], path: "/bab-munada" },
    { keywords: ["mafulminajlih", "bab mafulminajlih"], path: "/bab-mafulminajlih" },
    { keywords: ["mafulmaah", "bab mafulmaah"], path: "/bab-mafulmaah" },
    { keywords: ["mahfudotil asma", "bab mahfudotil asma"], path: "/bab-mahfudotil-asma" },
    { keywords: ["naat", "bab naat"], path: "/bab-naat" },
    { keywords: ["atof", "bab atof"], path: "/bab-atof" },
    { keywords: ["taukid", "bab taukid"], path: "/bab-taukid" },
    { keywords: ["badal", "bab badal"], path: "/bab-badal" },
  ];

  let closestMatch = { distance: Infinity, path: null };

  for (const group of keywordGroups) {
    for (const keyword of group.keywords) {
      const distance = levenshteinDistance(keyword, queryNormalized);
      if (distance < closestMatch.distance) {
        closestMatch = { distance, path: group.path };
      }
    }
  }

  const threshold = 5; // Sesuaikan threshold berdasarkan kebutuhan
  if (closestMatch.distance <= threshold) {
    return closestMatch.path;
  }

  return null;
};
