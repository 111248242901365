import Navbar from '../home/navbarApp'
import NavSlip from '../home/navSlip'
import '../css/mulai.css'
import {useEffect} from 'react'
import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom'
import Bawah from '../home/bawah'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Abstrak1 from '../sourc/holographic-monitor-with-programming-code-on-screen-1.png'
import Abstrak2 from '../sourc/holographic-web-development-coding-and-software-development-1.png'
import Abstrak3 from '../sourc/holographic-digital-wallet-and-online-banking-1.png'
import Abstrak4 from '../sourc/holographic-taking-notes-at-online-meeting-1.png'

function Baris1(){

    useEffect(() => {
        AOS.init({ duration: 1500, once: true });
      },);

    return(
        <>
        <div className="baris1">
            <Helmet>
              <title>sejarah ilmu nahwu</title>
              <meta name="description" content="Dahulu orang Arab berbicara bahasa Arab secara natural tanpa lahn (kesalahan), mereka berbicara dengan baik dan benar tanpa pernah belajar dan mempelajari kaidah-kaidah bahasa Arab sebelumnya" />
            </Helmet>
            <div className='baris1A'>
            <div className='barisas' data-aos="zoom-in">
                <h1>Pengertian</h1>
                <p>Ilmu nahwu dalam bahasa Inggris dapat di artikan dengan “Syntax”. Jadi Ilmu Nahwu adalah ilmu yang membahas asal-usul penyusunan kalimat dan kaidah-kaidah I’rab (harakat terakhir sebuah kata dalam suatu susunan kalimat).</p>
                <p>Singkatnya, Nahwu itu adalah ilmu gramatikal bahasa Arab.</p>
            </div>
            <div data-aos="zoom-in">
                <h1>Tujuan</h1>
                <p>Tujuan mempelajari ilmu ini adalah untuk menjaga lisan dari kesalahan dalam pelafazan bahasa Arab, dan untuk memahami Al-Quran dan Al-Hadits dengan benar, dimana keduanya adalah sumber utama syariat Islam.</p>
                <p>ilmu nahwu sangat berperan penting karna tanpa ilmu ini kita tidak dapat memahami makna yang terkandung dalam al-qur`an, hadis , fikih, tauhid, dan ilmu arab lain nya.</p>
            </div>
            <div data-aos="zoom-in">
                <h1>Sejarah</h1>
                <p>Dahulu orang Arab berbicara bahasa Arab secara natural tanpa lahn (kesalahan), mereka berbicara dengan baik dan benar tanpa pernah belajar dan mempelajari kaidah-kaidah bahasa Arab sebelumnya.
                Kondisi tersebut berlangsung lama, sampai Islam datang dan terjadi ekspansi besar-besaran, lalu orang-orang berbondong-bondong masuk Islam, baik Arab atau non Arab, dan berbaurlah antara orang Arab dengan non Arab.</p>
                <p>Dari hasil interaksi tersebut, terjadilah kesalahan-kesalahan pada lisan orang Arab dan orang-orang yang sedang mempelajari bahasa Arab.
                Di antara cerita awal-awal terjadinya lahn (kesalahan) di lisan orang Arab adalah sebagaimana yang tersebut di dalam sebuah riwayat, bahwa ada anak perempuan yang berkata : (مَا أَجمَلُ السَّمَاء؟)
                “Apa yang indah dari langit?” Padahal ia ingin mengatakan bahwa “alangkah indah langitnya”, tapi karena salah dalam memberi harakat akhir pada kata (أجمل) yang seharusnya dengan harakat fathah , namun ia mengatakannya dengan dhammah ( ــــُـــ ) yang berarti kalimat tanya, maka dijawab : ( نُجُومُهَا ) “Bintang-bintangnya”. Lalu perempuan itu pun berkata :”Bukan itu yang aku mau, aku tidak bertanya, tapi ingin mengungkapkan rasa takjubnya dengan langit”.</p>
                <p>dari pristiwa ini di buatlah qoedah(aturan-aturan) bahasa arab dengan tujuan supaya lebih mudah untuk memahami dan memaknai suatu kalimat tersebut.</p>
            </div>
            <div data-aos="zoom-in">
                <h1>Kenapa Di Namakan Ilmu Nahwu</h1>
                <p>Diriwayatkan dari Ali bin Abi Thalib radhiallahu anhu , dahulu ketika ia sedang membaca lembaran-lembaran, datanglah Abul Aswad Ad-Du`ali, dan ia bertanya :”Apa ini?”, lalu Ali pun berkata :”Aku memperhatikan perkataan orang-orang Arab, dan aku melihatnya sudah berubah akibat pergaulan mereka dengan orang-orang merah (yaitu orang-orang selain Arab), maka aku berkeinginan membuat sesuatu yang bisa dijadikan rujukan dan pegangan”. Maka ia pun berkata kepada Abul Aswad Ad-Du`ali:
                اُنْحُ هَذَا النَّحْوَ "Ikutilah jalan ini!"
                Dan yang dimaksud Ali bin Abi Thalib radhiallahu anhu adalah supaya Abul Aswad Ad-Du`ali membuat kaidah-kaidah untuk bahasa Arab.</p>
                <p>dari cerita ini maka terbentuklah nama ilmu nahwu sampai saat sekarang ini.</p>
            </div>
            <div data-aos="zoom-in">
                <h1>Referensi</h1>
                <p>pada pelajaran kali ini kami mengambil referensi dari kitab al-jurumiah yang di karang oleh ahli bahasa (ulama) dari maroko yang bernama abu abdillah sidi muhammad bin daud ash-shanhaji yang lebih di kenal dengan Ibnu Ajjurumi.</p>
            </div>
            </div>
        </div>
        </>
    )
}

function Baris2(){
    useEffect(() => {
        AOS.init({ duration: 1500, once: true });
      }, [])

      let navigate = useNavigate();
      useEffect(() => {
        const checkedItems = JSON.parse(localStorage.getItem('checkedItems')) || [];
        document.querySelectorAll('.baris2Ab p').forEach(p => {
          const itemName = p.textContent.trim();
          if (checkedItems.includes(itemName)) {
            p.innerHTML = `<i class="bi bi-check2"></i> ${itemName} <i class="bi bi-bookmark-dash reset-icon" style="display: inline;"></i>`;
          } else {
            p.innerHTML = `<i class="bi bi-chevron-right"></i> ${itemName} <i class="bi bi-bookmark-dash reset-icon" style="display: none;"></i>`;
          }
        });
      }, []);
    
      const handleClick=(e, itemName)=>{
        if (e.target.classList.contains('reset-icon')) {
          return;
        }

        let checkedItems = JSON.parse(localStorage.getItem('checkedItems')) || [];
        const pElement = e.target.closest('p');

        if (!checkedItems.includes(itemName)) {
          checkedItems.push(itemName);
          pElement.innerHTML = `<i class="bi bi-check2"></i> ${itemName} <i class="bi bi-bookmark-dash reset-icon" style="display: inline;"></i>`;
        } else {
        }
        localStorage.setItem('checkedItems', JSON.stringify(checkedItems));
        ClickKalam(itemName)
      };

      function ClickKalam(itemName) {
        if (itemName === "kalam") {
          navigate("/bab-kalam");
        } else if (itemName === "ikrob") {
          navigate("/bab-ikrob");
        } else if (itemName === "makrifat") {
          navigate("/bab-makrifat");
        }
        else if (itemName === "faslun") {
            navigate("/faslun");
        }
        else if (itemName === "af`al") {
            navigate("/bab-af`al");
        }
        else if (itemName === "marfuatil asma`") {
            navigate("/bab-marfuatil-asma");
        }
        else if (itemName === "fail") {
            navigate("/bab-fail");
        }
        else if (itemName === "naibul fail") {
            navigate("/bab-naibulfail");
        }
        else if (itemName === "mubtadak-khobar") {
            navigate("/bab-mubtadak-khobar");
        }
        else if (itemName === "khobar kana") {
            navigate("/bab-awamilidakhilatialalmubtadakwakhobar");
        }
        else if (itemName === "isim inna") {
            navigate("/bab-awamilidakhilatialalmubtadakwakhobar");
        }
        else if (itemName === "mansubatil asma`") {
            navigate("/bab-mansubatil-asma");
        }
        else if (itemName === "mafulunbih") {
            navigate("/bab-mafulunbih");
        }
        else if (itemName === "masdar") {
            navigate("/bab-masdar");
        }
        else if (itemName === "zorof") {
            navigate("/bab-zorofzaman-wamakan");
        }
        else if (itemName === "hal") {
            navigate("/bab-hal");
        }
        else if (itemName === "tamyiz") {
            navigate("/bab-tamyiz");
        }
        else if (itemName === "mustasna") {
            navigate("/bab-istisna");
        }
        else if (itemName === "isim la") {
            navigate("/bab-laa");
        }
        else if (itemName === "munada") {
            navigate("/bab-munada");
        }
        else if (itemName === "maf`ul minajlih") {
            navigate("/bab-mafulminajlih");
        }
        else if (itemName === "maf`ul ma`ah") {
            navigate("/bab-mafulma`ah");
        }
        else if (itemName === "khobar kana") {
            navigate("/bab-awamilidakhilatialalmubtadakwakhobar");
        }
        else if (itemName === "isim inna") {
            navigate("/bab-awamilidakhilatialalmubtadakwakhobar");
        }
        else if (itemName === "mahfudun bilharfi") {
            navigate("/bab-mahfudotil-asma");
        }
        else if (itemName === "mahfudun bilidofah") {
            navigate("/bab-mahfudotil-asma");
        }
        else if (itemName === "tabi`") {
            navigate("/bab-naat");
        }
        else if (itemName === "na`at") {
            navigate("/bab-makrifat");
        }
        else if (itemName === "atof") {
            navigate("/bab-makrifat");
        }
        else if (itemName === "taukid") {
            navigate("/bab-makrifat");
        }
        else if (itemName === "badal") {
            navigate("/bab-makrifat");
        }
      }
      const handleResetClick = (e) => {
        if (e.target.classList.contains('reset-icon')) {
          const pElement = e.target.closest('p');
          const itemName = pElement.textContent.trim();
          let checkedItems = JSON.parse(localStorage.getItem('checkedItems')) || [];
          checkedItems = checkedItems.filter(item => item !== itemName);
          localStorage.setItem('checkedItems', JSON.stringify(checkedItems));
          pElement.innerHTML = `<i class="bi bi-chevron-right"></i> ${itemName} <i class="bi bi-bookmark-dash reset-icon" style="display: none;"></i>`;

        }
      };

    return(
        <>
        <div className='baris2' data-aos="zoom-in">
            <div className='baris2A'
            style={{
            backgroundImage:`url(${Abstrak1})`,
            }}>
                <div className='baris2Aa'>
                    <img src={Abstrak1} alt='gambar'></img>
                    <div> 
                        <h1>pendahuluan</h1>
                        <p>awal mula kita untuk mengenal apa yang di maksut dengan ilmu nahwu</p>
                    </div>
                </div>
                <div className='baris2Ab' onClick={handleResetClick}>
                    <p onClick={(e) => handleClick(e, 'kalam')}><i className="bi bi-chevron-right"/> kalam<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'ikrob')}><i className="bi bi-chevron-right"/> ikrob<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'makrifat')}><i className="bi bi-chevron-right"/> makrifat<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'faslun')}><i className="bi bi-chevron-right"/> faslun<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'af`al')}><i className="bi bi-chevron-right"/> af`al<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                </div>
            </div>
        </div>
        <div className='baris2' data-aos="zoom-in">
            <div className='baris2A' style={{
            backgroundImage:`url(${Abstrak2})`
            }}>
                <div className='baris2Aa'>
                    <img src={Abstrak2} alt='gambar'></img>
                    <div>
                        <h1>marfuatil asama`</h1>
                        <p>isim isim yang di rofa`kan itu ada tujuh 7</p>
                    </div>
                </div>
                <div className='baris2Ab' onClick={handleResetClick}>
                    <p onClick={(e) => handleClick(e, 'marfuatil asma`')}><i className="bi bi-chevron-right"/> marfuatil asma`<i className="bi bi-bookmark-dash reset-icon"style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'fail')}><i className="bi bi-chevron-right"/> fail<i className="bi bi-bookmark-dash reset-icon"style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'naibul fail')}><i className="bi bi-chevron-right"/> naibul fail<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'mubtadak-khobar')}><i className="bi bi-chevron-right"/> mubtadak khobar<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'khobar kana')}><i className="bi bi-chevron-right"/> khobar kana<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'isim inna')}><i className="bi bi-chevron-right"/> isim inna<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'tabi`')}><i className="bi bi-chevron-right"/>tabi`<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                </div>
            </div>
        </div>
        <div className='baris2' data-aos="zoom-in">
            <div className='baris2A'style={{
            backgroundImage:`url(${Abstrak3})`
            }}> 
                <div className='baris2Aa'>
                    <img src={Abstrak3} alt='gambar'></img>
                    <div>
                        <h1>mansubatil asma`</h1>
                        <p>isim-isim yang di nasabkan itu ada lima belas 15</p>
                    </div>
                </div>
                <div className='baris2Ab' onClick={handleResetClick}>
                    <p onClick={(e) => handleClick(e, 'mansubatil asma`')}><i className="bi bi-chevron-right"/> mansubatil asma`<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'mafulunbih')}><i className="bi bi-chevron-right"/> mafulunbih<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'masdar')}><i className="bi bi-chevron-right"/> masdar<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'zorof')}><i className="bi bi-chevron-right"/> zorof<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'hal')}><i className="bi bi-chevron-right"/> hal<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'tamyiz')}><i className="bi bi-chevron-right"/> tamyiz<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'mustasna')}><i className="bi bi-chevron-right"/> mustasna<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'isim la')}><i className="bi bi-chevron-right"/> isim la<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'munada')}><i className="bi bi-chevron-right"/> munada<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'maf`ul minajlih')}><i className="bi bi-chevron-right"/> maf`ul minajlih<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'maf`ul ma`ah')}><i className="bi bi-chevron-right"/> maf`ul ma`ah<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'khobar kana')}><i className="bi bi-chevron-right"/> khobar kana<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'isim inna')}><i className="bi bi-chevron-right"/> isim inna<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'tabi`')}><i className="bi bi-chevron-right"/>tabi`<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                </div>
            </div>
        </div>
        <div className='baris2' data-aos="zoom-in">
            <div className='baris2A'style={{
            backgroundImage:`url(${Abstrak4})`
            }}>
                <div className='baris2Aa'>
                    <img src={Abstrak4} alt='gambar'></img>
                    <div>
                        <h1>makhfudotil asma`</h1>
                        <p>isim yang di jarkan itu ada tiga 3</p>
                    </div>
                </div>
                <div className='baris2Ab' onClick={handleResetClick}>
                    <p onClick={(e) => handleClick(e, 'mahfudun bilharfi')}><i className="bi bi-chevron-right"/>mahfudun bilharfi<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'mahfudun bilidofah')}><i className="bi bi-chevron-right"/>mahfudun bilidofah<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'tabi`')}><i className="bi bi-chevron-right"/>tabi`<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                </div>
            </div>
        </div>
        <div className='baris2' data-aos="zoom-in">
            <div className='baris2A'style={{
            backgroundImage:`url(${Abstrak4})`
            }}>
                <div className='baris2Aa'>
                    <img src={Abstrak4} alt='gambar'></img>
                    <div>
                        <h1>yang mengikut  "tabi`" </h1>
                        <p>di rofa`kan apabila rofa`, di nasabkan apabila nasab, di jarkan apabila jar</p>
                    </div>
                </div>
                <div className='baris2Ab' onClick={handleResetClick}>
                    <p onClick={(e) => handleClick(e, 'na`at')}><i className="bi bi-chevron-right"/> na`at<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'atof')}><i className="bi bi-chevron-right"/> atof<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'taukid')}><i className="bi bi-chevron-right"/> taukid<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                    <p onClick={(e) => handleClick(e, 'badal')}><i className="bi bi-chevron-right"/> badal<i className="bi bi-bookmark-dash reset-icon" style={{display: 'none'}}/></p>
                </div>
            </div>
        </div>
        </>
    )
}
export default function Mulai(){
    return(
        <>
        <Navbar/>
        <NavSlip/>
        <Baris1/>
        <Baris2/>
        <Bawah/>
        </>
    )
}