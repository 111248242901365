import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import { Helmet } from 'react-helmet';

function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
          <title>bab kalam</title>
          <meta name="description" content="Kalam adalah suara yang tersusun, memberikan faidah, dan dengan wado`(di sengaja dan berbhasa arab)" />
        </Helmet>
        <h1 className='h1'>بَابُ الكَلَامِ</h1>
        <h2>الكَلَامُ هُوَ اللَّفْظُ المُرَكَّبُ المُفِيْدُ بِالوَضْعِ</h2>
        <p>Kalam adalah suara yang tersusun, memberikan faidah, dan dengan wado`(di sengaja dan berbhasa arab)</p>
        <h2>وَأَقْسَامُهُ ثَلَاثَةٌ : اِسْمٌ، وَفِعْلٌ، وَحَرْفٌ جَاءَ لِمَعْنًى</h2>
        <p>Kalam terbagi tiga: isim - fi`il - dan huruf yang memiliki arti</p>
        <h2>فَالِاسْمُ يُعْرَفُ بِالخَفْضِ وَالتَّنْوِينِ، وَدُخُولِ اَلْأَلِفِ وَاللَّامِ، وَحُرُوفِ اَلْخَفْضِ وَهِيَ: (مِنْ)، وَ(إِلَى)، وَ(عَنْ)، وَ(عَلَى)، وَ(فِي)، وَ(رُبَّ)، وَ(الْبَاءُ)، وَ(الْكَافُ)، وَ(اللَّامُ)، وَحُرُوفِ اَلْقَسَمِ وَهِيَ: (الْوَاوُ)، وَ(الْبَاءُ)، وَ(التَّاءُ)</h2>
        <p>Isim dapat diketahui dengan: khofdh, tanwin, masuknya alif lam, dan huruf-huruf khofdh yaitu : min, ilaa, `an, `alaa, fiy, rubba, al-baa-u, al-kaafu, al-laamu. Dan huruf-huruf sumpah yaitu : al-waawu, al baa-u, dan at-taa-u</p>
        <h2>والفِعْلُ يُعْرَفُ بِـ(قَدْ) وَ(السِّيْنِ) وَ(سَوْفَ) وَ(تَاءِ التَّأْنِيْثِ السَّاكِنَةِ)</h2>
        <p>Dan fi`il dapat dikenali dengan : qod, huruf sin, saufa, dan ta` ta`nits sakinah</p>
        <h2>وَالحَرْفُ مَالَا يَصْلُحُ مَعَهُ دَلِيْلُ الاِسْمِ وَلَا دَلِيْلُ الفِعْلِ</h2>
        <p>Sementara huruf tidak layak ditandai dengan tanda isim maupun fi`il</p>
        <div className='ket'>
          <h3>singkat nya</h3>
          <ul>
            <li>isim adalah setiap nama benda</li>
            <li>fi`il adalah setiap pekerjaan</li>
            <li>huruf bukan nama bukan pekerjaan</li>
          </ul>
        </div>
        <div className='ket'>
          <h3>huruf-huruf khofad</h3>
          <ul>
            <li>مِنْ : dari</li>
            <li>إِلَى : kepada</li>
            <li>عَنْ : dari </li>
            <li>عَلَى : :atas</li>
            <li>فِي : pada</li>
            <li>رُبَّ : kebanyakan</li>
            <li>الْبَاءُ : dengan</li>
            <li>الْكَافُ : seperti</li>
            <li>اللَّامُ : untuk</li>
          </ul>
        </div>
        <div className='ket'>
          <h3>pertanda isim</h3> 
          <ul>
            <li>khofad</li>
            <li>tanwin</li>
            <li>masuk alif lam</li>
            <li>masuk huruf khofad</li>
            <li>masuk huruf kosam (sumpah)</li>
          </ul>
        </div>
        <div className='ket'>
          <h3>pertanda fi`il</h3> 
          <ul>
            <li>قَدْ : sesungguh nya</li>
            <li>السِّيْنِ : selagi (dekat)</li>
            <li>سَوْفَ : selagi (jauh)</li>
            <li>تَاءِ التَّأْنِيْثِ السَّاكِنَةِ : ta` tanda perempuan</li>
          </ul>
        </div>
        </>
    )
}

function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
          {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i> }
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-kalam"></p>
          <h4>halaman awal</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab kalam</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab ikrob</h4>
          <p className="bi bi-chevron-right" data-url="/bab-ikrob"></p>
        </div>
      </div>
    </div>
  );
}

export default function Kalam(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}