import HalamanAwal from "./home/halamanAwal"
import Mulai from "./halamanPertaman.js/mulai";
import Kalam from "./halamanPertaman.js/BabKalam";
import Ikrob from "./halamanPertaman.js/BabIkrob";
import Makrifat from "./halamanPertaman.js/BabMakrifat";
import Faslun from "./halamanPertaman.js/BabFaslun";
import Afal from "./halamanPertaman.js/BabAfal";
import Marfuat from "./halamanPertaman.js/MarfuatilAsma";
import Fail from "./halamanPertaman.js/BabFail";
import NaibulFail from "./halamanPertaman.js/Naibulfail";
import MubtadakKhobar from "./halamanPertaman.js/MubtadakKhobar";
import AmilNAwasikh from "./halamanPertaman.js/BabAwamilit";
import Mansubat from "./halamanPertaman.js/MansubatilAsma";
import MAfulunbih from "./halamanPertaman.js/MafulunBih";
import Masdar from "./halamanPertaman.js/BabMasdar";
import Zorof from "./halamanPertaman.js/BabZorof";
import HAl from "./halamanPertaman.js/BabHal";
import TAmyiz from "./halamanPertaman.js/BabTamyiz";
import Istisna from "./halamanPertaman.js/BabIstisna";
import Laa from "./halamanPertaman.js/BabLaa";
import Munada from "./halamanPertaman.js/BabMunada";
import MafulMInajlih from "./halamanPertaman.js/MafulAjlih";
import MAfulMaah from "./halamanPertaman.js/MafulMaah";
import MAkhfudot from "./halamanPertaman.js/MahfudotilAsma";
import Naat from "./halamanPertaman.js/BabNaat";
import Atof from "./halamanPertaman.js/BabAtof";
import Taukid from "./halamanPertaman.js/BabTaukit";
import Badal from "./halamanPertaman.js/BabBadal";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


export default function App(){
    return (
        <Router>
          <Routes>
            <Route exact path="/" element={<HalamanAwal/>} />
            <Route path="/mulai" element={<Mulai/>}/>
            <Route path="/bab-kalam" element={<Kalam/>}/>
            <Route path="/bab-ikrob" element={<Ikrob/>}/>
            <Route path="/bab-makrifat" element={<Makrifat/>}/>
            <Route path="/faslun" element={<Faslun/>}/>
            <Route path="/bab-afal" element={<Afal/>}/>
            <Route path="/bab-marfuatil-asma" element={<Marfuat/>}/>
            <Route path="/bab-fail" element={<Fail/>}/>
            <Route path="/bab-naibulfail" element={<NaibulFail/>}/>
            <Route path="/bab-mubtadak-khobar" element={<MubtadakKhobar/>}/>
            <Route path="/bab-awamilidakhilatialalmubtadakwakhobar" element={<AmilNAwasikh/>}/>
            <Route path="/bab-mansubatil-asma" element={<Mansubat/>}/>
            <Route path="/bab-mafulunbih" element={<MAfulunbih/>}/>
            <Route path="/bab-masdar" element={<Masdar/>}/>
            <Route path="/bab-zorofzaman-wamakan" element={<Zorof/>}/>
            <Route path="/bab-hal" element={<HAl/>}/>
            <Route path="/bab-tamyiz" element={<TAmyiz/>}/>
            <Route path="/bab-istisna" element={<Istisna/>}/>
            <Route path="/bab-laa" element={<Laa/>}/>
            <Route path="/bab-munada" element={<Munada/>}/>
            <Route path="/bab-mafulminajlih" element={<MafulMInajlih/>}/>
            <Route path="/bab-mafulmaah" element={<MAfulMaah/>}/>
            <Route path="/bab-mahfudotil-asma" element={<MAkhfudot/>}/>
            <Route path="/bab-naat" element={<Naat/>}/>
            <Route path="/bab-atof" element={<Atof/>}/>
            <Route path="/bab-taukid" element={<Taukid/>}/>
            <Route path="/bab-badal" element={<Badal/>}/>
          </Routes>
        </Router>
      );
}
