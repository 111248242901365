import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab zorrof zaman wa makan</title>
              <meta name="description" content="Zhorf zaman yaitu keterangan waktu yang dibaca nashab dengan perkiraan makna (فِي)" />
        </Helmet>
        <h1 className='h1'>بَابُ ظَرْفِ الزَّمَانِ وَظَرْفِ المَكَانِ</h1>
        <h2>ظَرْفُ الزَّمَانِ هُوَ : اِسْمُ الزَّمَانِ المَنْصُوْبُ بِتَقْدِيْرِ (فِي)</h2>
        <p>Zhorf zaman yaitu keterangan waktu yang dibaca nashab dengan perkiraan makna (فِي)</p>
        <h2>نَحْوُ : (اليَوْمَ)، وَ(اللَّيْلَةَ)، وَ(غُدْوَةً)، وَ(بُكْرَةً)، وَ(سَحَرًا)، وَ(غَدًا)، وَ(عَتَمَةً)، وَ(صَبَاحًا)، وَ(مَسَاءً)، وَ(أَبَدًا)، وَ(أَمَدًا)، وَ(حِينًا)، وَمَا أَشْبَهَ ذَلِكَ</h2>
        <p>contoh nya: (اليَوْمَ)، وَ(اللَّيْلَةَ)، وَ(غُدْوَةً)، وَ(بُكْرَةً)، وَ(سَحَرًا)، وَ(غَدًا)، وَ(عَتَمَةً)، وَ(صَبَاحًا)، وَ(مَسَاءً)، وَ(أَبَدًا)، وَ(أَمَدًا)، وَ(حِينًا) dan apa yang menyerupai demukian</p>
        <h2>وَظَرْفُ المَكَانِ: هُوَ اِسْمُ المَكَانِ المَنْصُوبُ بِتَقْدِيرِ (فِي)</h2>
        <p>Zharaf makan yaitu keterangan tempat yang dibaca manshub dengan perkiraan makna (فِي)</p>
        <h2>نَحْوُ: (أَمَامَ)، وَ(خَلْفَ)، وَ(قُدَّامَ)، وَ(وَرَاءَ)، وَ(فَوْقَ)، وَ(تَحْتَ)، وَ(عِنْدَ)، وَ(مَعَ)، وَ(إِزَاءَ)، وَ(حِذَاءَ)، وَ(تِلْقَاءَ)، وَ(ثَمَّ)، وَ(هُنَا)، وَمَا أَشْبَهَ ذَلِكَ</h2>
        <p>contoh nya: (أَمَامَ)، وَ(خَلْفَ)، وَ(قُدَّامَ)، وَ(وَرَاءَ)، وَ(فَوْقَ)، وَ(تَحْتَ)، وَ(عِنْدَ)، وَ(مَعَ)، وَ(إِزَاءَ)، وَ(حِذَاءَ)، وَ(تِلْقَاءَ)، وَ(ثَمَّ)، وَ(هُنَا) dan apa seumpama demikian</p>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-masdar"></p>
          <h4>bab masdar</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab zorof zaman wa makan</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab hal</h4>
          <p className="bi bi-chevron-right" data-url="/bab-hal"></p>
        </div>
      </div>
    </div>
  );
}

export default function Zorof(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}