import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import rofak from '../sourc/rofak.png'
import rofak1 from '../sourc/rofak1.png'
import rofak2 from '../sourc/rofak2.png'
import rofak3 from '../sourc/rofak3.png'
import rofak4 from '../sourc/rofak4.png'
import nasab from '../sourc/nasab.png'
import nasab1 from '../sourc/nasab1.png'
import nasab2 from '../sourc/nasab2.png'
import nasab3 from '../sourc/nasab3.png'
import nasab4 from '../sourc/nasab4.png'
import nasab5 from '../sourc/nasab5.png'
import kofad from '../sourc/khofad.png'
import kofad1 from '../sourc/khofad1.png'
import kofad2 from '../sourc/khofad2.png'
import kofad3 from '../sourc/khofad3.png'
import jazam from '../sourc/jazam.png'
import jazam1 from '../sourc/jazam1.png'
import jazam2 from '../sourc/jazam2.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab makrifat</title>
              <meta name="description" content="rofa` memiliki 4 pertanda yaitu: Dommah, Wawu(و), 3 Alif (ا), 4 Nun(ن)" />
        </Helmet>
        <h1 className='h1'>بَابُ مَعْرِفَةِ عَلَامَاتِ اﻹِعْرَابِ</h1>
        <h2>لِلرَّفْعِ أَرْبَعُ عَلَامَاتٍ : الضَّمَّةُ وَالْوَاوُ وَالْأَلِفُ وَالنُّوْنُ</h2>
        <p>rofa` memiliki 4 pertanda yaitu: Dommah, Wawu(و), 3 Alif (ا), 4 Nun(ن)</p>
        <img src={rofak} alt='contoh'></img>
        <h2>فَأَمَّا الضَّمَّةُ؛ فَتَكُوْنُ عَلَامَةً لِلرَّفْعِ فِي أَرْبَعَةِ مَوَاضِعَ : فِي الْاِسْمِ المُفْرَدِ وَجَمْعِ التَّكْسِيْرِ وَجَمْعِ المُؤَنَّثِ السَّالِمِ وَالفِعْلِ المُضَارِعِ الَّذِي لَمْ يَتَّصِلْ بِآخِرِهِ شَيْءٌ.</h2>
        <p>Dhammah menjadi tanda bagi rofa` pada 4 tempat yaitu: isim Mufrod, Jamak Taksir, Jamak Muannats Salim, Fi`il Mudhari` yang akhirannya tidak bersambung dengan sesuatu apapun</p>
        <img src={rofak1} alt='contoh'></img>
        <h2>وَأَمَّا الوَاوُ فَتَكُوْنُ عَلَامَةً لِلرَّفْعِ فِي مَوْضِعَيْنِ : فِي جَمْعِ المُذَكَّرِ السَّالِمِ وَفِي الأَسْمَاءِ الخَمْسَةِ، وَهِيَ : (أَبُوْكَ)، وَ(أَخُوْكَ)، وَ(حَمُوْكِ)، وَ(فُوْكَ)، وَ(ذُو مَالٍ)</h2>
        <p>wawu(و) menjadi tanda bagi rofa` pada 2 tempat yaitu: Jamak Mudzakkar Salim, Al-Asma` Al-Khomsah أَبُوْكَ (ayahmu); أَخُوْكَ (saudaramu); حَمُوْكِ (iparmu); فُوْكَ (mulutmu); ذُوْ مَالٍ (yang memiliki harta)</p>
        <img src={rofak2} alt='contoh'></img>
        <h2>وَأَمَّا الأَلِفُ فَتَكُوْنُ عَلَامَةً لِلرَّفْعِ فِي تَثْنِيَةِ الأَسْمَاءِ خَاصَةً</h2>
        <p>alif(ا) menjadi tanda bagi rofa` pada isim mutsanna(asmaul khossoh)</p>
        <img src={rofak3} alt='contoh'></img>
        <h2>وَأَمَّا النُّوْنُ فَتَكُوْنُ عَلَامَةً لِلرَّفْعِ فِي الفِعْلِ المُضَارِعِ إِذَا اتَّصَلَ بِهِ ضَمِيْرُ تَثْنِيَةٍ، أَوْ ضَمِيْرُ جَمْعٍ، أَوْ ضَمِيْرُ المُؤَنَّثَةِ المُخَاطَبَةِ</h2>
        <p>nun(ن) menjadi tanda bagi rofa` pada fi`il mudhari` apabila ia bersambung dengan dhomir tasniyah atau domir jamak atau domir muannats mukhotobah</p>
        <img src={rofak4} alt='contoh'></img>
        <h2>وَلِلنَّصْبِ خَمْسُ عَلَامَاتٍ : الفَتْحَةُ وَالأَلِفُ وَالكَسْرَةُ وَاليَاءُ وَحَذْفُ النُّوْنِ</h2>
        <p>Nashob memiliki 5 tanda yaitu: fathah, alif, kasroh, yaa, hadzfun nun(membuang huruf nun)</p>
        <img src={nasab} alt='contoh'></img>
        <h2>فَأَمَّا الفَتْحَةُ فَتَكُوْنُ عَلَامَةً لِلنَّصْبِ فِي ثَلَاثَةِ مَوَاضِعَ : فِي الاِسْمِ المُفْرَدِ، وَجَمْعِ التَّكْسِيْرِ، وَالفِعْلِ المُضَارِعِ إِذَا دَخَلَ عَلَيْهِ نَاصِبٌ وَلَمْ يَتَّصِلْ بِآخِرِهِ شَيْءٌ</h2>
        <p>Fathah menjadi tanda bagi nashob pada 3 tempat yaitu: Isim Mufrod, Jamak Taksir, Fi`il Mudhari` yang masuk amil nashab dan di akhirannya tidak bersambung dengan sesuatu apapun</p>
        <img src={nasab1} alt='contoh'></img>
        <h2>وَأَمَّا الأَلِفُ فَتَكُوْنُ عَلَامَةً لِلنَّصْبِ فِي الأَسْمَاءِ الخَمْسَةِ، نَحْوُ : (رَأَيْتُ أَبَاكَ وَأَخَاكَ)</h2>
        <p>Alif menjadi tanda bagi nashob pada: al-asma` al-khomsah Seperti contohnya : Roaitu Abaaka wa akhooka (aku melihat ayah dan saudaramu)</p>
        <img src={nasab2} alt='contoh'></img>
        <h2>وَأَمَّا الكَسْرَةُ فَتَكُوْنُ عَلَامَةً لِلنَّصْبِ فِي جَمْعِ المُؤَنَّثِ السَّالِمِ</h2>
        <p>kasroh menjadi tanda bagi nashob pada: Jamak Muannats Salim</p>
        <img src={nasab3} alt='contoh'></img>
        <h2>وَأَمَّا اليَاءُ فَتَكُوْنُ عَلَامَةً لِلنَّصْبِ فِي التَّثْنِيَةِ وَالجَمْعِ</h2>
        <p>yaa menjadi tanda bagi nashob pada: tasniyah dan jamak</p>
        <img src={nasab4} alt='contoh'></img>
        <h2>وَأَمَّا حَذْفُ النُّوْنِ فَيَكُوْنُ عَلَامَةً لِلنَّصْبِ فِي الأَفْعَالِ الخَمْسَةِ الَّتِي رَفْعُهَا بِثُبُوْتِ النُّوْنِ</h2>
        <p>hadzfun nun (pembuangan huruf nun) menjadi tanda bagi nashob pada fi`il yang lima yang rofa`nya dengan adanya huruf nun</p>
        <img src={nasab5} alt='contoh'></img>
        <h2>وَلِلْخَفْضِ ثَلَاثُ عَلَامَاتٍ : الكَسْرَةُ، وَاليَاءُ، وَالفَتْحَةُ</h2>
        <p>Khofdh memiliki 3 tanda yaitu: Kasroh, Ya`, Fathah</p>
        <img src={kofad} alt='contoh'></img>
        <h2>فَأَمَّا الكَسْرَةُ؛ فَتَكُوْنُ عَلَامَةً لِلْخَفْضِ فِي ثَلَاثَةِ مَوَاضِعَ : فِي الِاسْمِ المُفْرَدِ المُنْصَرِفِ، وَجَمْعِ التَّكْسِيْرِ المُنْصَرِفِ، وَجَمْعِ المُؤَنَّثِ السَّالِمِ</h2>
        <p>kasroh menjadi tanda bagi khofdh di 3 tempat: Isim Mufrod Munshorif, Jamak Taksir Munshorif, Jamak Muannats Salim</p>
        <img src={kofad1} alt='contoh'></img>
        <h2>وَأَمَّا اليَاءُ؛ فَتَكُوْنُ عَلَامَةً لِلْخَفْضِ فِي ثَلَاثَةِ مَوَاضِعَ : فِي الأَسْمَاءِ الخَمْسَةِ، وَفِي التَّثْنِيَةِ وَالجَمْعِ</h2>
        <p>Ya` menjadi tanda bagi khofdh pada 3 tempat: Al-Asma`` Al-Khomsah, Tatsniyah, Jamak</p>
        <img src={kofad2} alt='contoh'></img>
        <h2>وَأَمَّا الفَتْحَةُ؛ فَتَكُوْنُ عَلَامَةً لِلْخَفْضِ فِي الاِسْمِ الَّذِي لَا يَنْصَرِفُ</h2>
        <p>fathah menjadi tanda bagi khofdh pada: isim yang tidak munshorif (isim goiru munshorif)</p>
        <img src={kofad3} alt='contoh'></img>
        <h2>وَلِلْجَزْمِ عَلَامَتَانِ : السُّكُوْنُ، وَالحَذْفُ</h2>
        <p>Tanda Jazm ada 2 : Sukun, Hadzfu (pembuangan)</p>
        <img src={jazam} alt='contoh'></img>
        <h2>فَأَمَّا السُّكُوْنُ؛ فَيَكُوْنُ عَلَامَةً لِلْجَزْمِ فِي الفِعْلِ المُضَارِعِ الصَّحِيْحِ الآخِرِ</h2>
        <p>sukun menjadi tanda bagi jazm pada: fi`il mudhari` shahih akhir</p>
        <img src={jazam1} alt='contoh'></img>
        <h2>وَأَمَّا الحَذْفُ؛ فَيَكُوْنُ عَلَامَةً لِلْجَزْمِ فِي الفِعْلِ المُضَارِعِ المُعْتَلِّ الآخِرِ، وَفِي الأَفْعَالِ الخَمْسَةِ الَّتِي رَفْعُهَا بِثَبَاتِ النُّوْنِ</h2>
        <p>hadzfu (membuang) menjadi tanda bagi jazm pada: fi`il mudhari` mu`tal akhir dan di fi`il yang lima yang rofa`nya dengan tetapnya huruf nun</p>
        <img src={jazam2} alt='contoh'></img>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-ikrob"></p>
          <h4>bab ikrob</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab makrifat alamatil ikrob</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab faslun</h4>
          <p className="bi bi-chevron-right" data-url="/faslun"></p>
        </div>
      </div>
    </div>
  );
}

export default function Makrifat(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}