import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import mubtadak1 from '../sourc/mubtadak.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab mubtadak wa khobar</title>
              <meta name="description" content="Mubtada` adalah Isim yang di rofa`kan yang terbebas dari amil lafzhiyah" />
        </Helmet>
        <h1 className='h1'>بَابُ المُبْتَدَأِ وَالخَبَرِ</h1>
        <h2>المُبْتَدَأُ : هُوَ الاِسْمُ المَرْفُوْعُ العَارِي عَنِ العَوَامِلِ اللَّفْظِيَّةِ</h2>
        <p>Mubtada` adalah Isim yang di rofa`kan yang terbebas dari amil" lafzhiyah</p>
        <h2>وَالخَبَرُ : هُوَ الاِسْمُ المَرْفُوْعُ المُسْنَدُ إِلَيْهِ، نَحْوُ قَوْلِكَ: (زَيْدٌ قَائِمٌ)، وَ(الزَّيْدَانِ قَائِمَانِ)، وَ(الزَّيْدُوْنَ قَائِمُوْنَ)</h2>
        <p>Sedangkan khobar adalah isim yang di rofa`kan yang disandarkan kepada mubtada` seperti contoh: (زَيْدٌ قَائِمٌ)، وَ(الزَّيْدَانِ قَائِمَانِ)، وَ(الزَّيْدُوْنَ قَائِمُوْنَ)</p>
        <img src={mubtadak1} alt='contoh mubtadak khobar'></img>
        <h2>وَالمُبْتَدَأُ قِسْمَانِ : ظَاهِرٌ وَمُضْمَرٌ. فَالظَّاهِرُ مَا تَقَدَّمَ ذِكْرُهُ</h2>
        <p>mubtada` terbagi menjadi 2 macam, yaitu isim zhohir dan isim dhamir. Adapun isim zhahir contohnya seperti yang telah disebutkan di atas</p>
        <h2>وَالمُضْمَرُ اثْنَا عَشَرَ؛ وَهِيَ : (أَنَا)، وَ(نَحْنُ)، وَ(أَنْتَ)، وَ(أَنْتِ)، وَ(أَنْتُمَا)، وَ(أَنْتُمْ)، وَ(أَنْتُنَّ)، وَ(هُوَ)، وَ(هِيَ)، وَ(هُمَا)، وَ(هُمْ)، وَ(هُنَّ). نَحْوُ قَوْلِكَ: (أَنَا قَائِمٌ) وَ(نَحْنُ قَائِمُوْنَ) وَمَا أَشْبَهَ ذَلِكَ</h2>
        <p>Sedangkan isim dhamir ada 12, yaitu: (أَنَا)، وَ(نَحْنُ)، وَ(أَنْتَ)، وَ(أَنْتِ)، وَ(أَنْتُمَا)، وَ(أَنْتُمْ)، وَ(أَنْتُنَّ)، وَ(هُوَ)، وَ(هِيَ)، وَ(هُمَا)، وَ(هُمْ)، وَ(هُنَّ)</p>
        <h2>وَالخَبَرُ قِسْمَانِ :مُفْرَدٌ، وَ غَيْرُ مُفْرَدٍ</h2>
        <p>khobar terbagi menjadi 2 mufrod, dan goiru mufrod</p>
        <h2>فَالمُفْرَدُ؛ نَحْوُ قَوْلِكَ : (زَيْدٌ قَائِمٌ)</h2>
        <p>mufrod contohnya : زَيْدٌ قَائِمٌ (zaid berdiri)</p>
        <h2>وَغَيْرُ المُفْرَدِ أَرْبَعَةُ أَشْيَاءَ : الجَارُّ وَالمَجْرُوْرُ، وَالظَّرْفُ، وَالفِعْلُ مَعَ فَاعِلِهِ، وَالمُبْتَدَأُ مَعَ خَبَرِهِ. نَحْوُ قَوْلِكَ : (زَيْدٌ فِي الدَّارِ)، وَ(زَيْدٌ عِنْدَكَ)، وَ(زَيْدٌ قَامَ أَبُوْهُ)، وَ(زَيْدٌ جَارِيَتُهُ ذَاهِبَةٌ)</h2>
        <p>goiru mufrod ada 4 macam : jar majrur, zorof, fi`il bersama fa`ilnya, mubtada` bersama khobar-nya. Contoh : (زَيْدٌ فِي الدَّارِ)، وَ(زَيْدٌ عِنْدَكَ)، وَ(زَيْدٌ قَامَ أَبُوْهُ)، وَ(زَيْدٌ جَارِيَتُهُ ذَاهِبَةٌ)</p>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-naibulfail"></p>
          <h4>bab maf`ul allazi lam yusamma fa`iluhu</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab mubtadak wa khobar</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab awamid dhakhilati alal mubtadak wa khobar</h4>
          <p className="bi bi-chevron-right" data-url="/bab-awamilidakhilatialalmubtadakwakhobar"></p>
        </div>
      </div>
    </div>
  );
}

export default function MubtadakKhobar(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}