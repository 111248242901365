import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import { Helmet } from 'react-helmet';

function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab naat</title>
              <meta name="description" content="Na`at adalah isim yang mengikuti man`ut (isim yang disifati) pada keadaan rafa`, nashab, khafadh, ma`rifah, dan nakirah-nya" />
        </Helmet>
        <h1 className='h1'>بَابُ النَّعْتِ</h1>
        <h2>النَّعْتُ تَابِعٌ لِلْمَنْعُوتِ فِي رَفْعِهِ وَنَصْبِهِ وَخَفْضِهِ، وَتَعْرِيفِهِ وَتَنْكِيرِهِ</h2>
        <p>Na`at adalah isim yang mengikuti man`ut (isim yang disifati) pada keadaan rafa`, nashab, khafadh, ma`rifah, dan nakirah-nya</p>
        <h2>تَقُولُ: (قَامَ زَيْدٌ اَلْعَاقِلُ)، وَ(رَأَيْتُ زَيْدًا العَاقِلَ)، وَ(مَرَرْتُ بِزَيْدٍ اَلْعَاقِلِ)</h2>
        <p>kamu katakan: قَامَ زَيْدٌ اَلْعَاقِلُ(Zaid yang cerdas itu telah berdiri), رَأَيْتُ زَيْدًا اَلْعَاقِلَ(Aku melihat Zaid yang cerdas itu), مَرَرْتُ بِزَيْدٍ اَلْعَاقِلِ(Aku berpapasan dengan Zaid yang cerdas itu)</p>
        <h2>وَالمَعْرِفَةُ خَمْسَةُ أَشْيَاءَ</h2>
        <p>ma`rifah terbagi menjadi 5 jenis, yaitu</p>
        <h2>اَلِاسْمُ اَلْمُضْمَرُ؛ نَحْوُ: (أَنَا)، وَ(أَنْتَ)</h2>
        <p>isim dhamir (kata ganti); contohnya: أَنَا وَأَنْتَ</p>
        <h2>وَالِاسْمُ اَلْعَلَمُ؛ نَحْوُ: (زَيْدٍ)، وَ(مَكَّةَ)</h2>
        <p>Isim alam (nama); contohnya: زَيْدٍ وَمَكَّةَ</p>
        <h2>وَالِاسْمُ اَلْمُبْهَمُ؛ نَحْوُ: (هَذَا)، وَ(هَذِهِ)، وَ(هَؤُلَاءِ)</h2>
        <p>Isim mubham (kata tunjuk); contohnya: هَذَا, وَهَذِهِ, وَهَؤُلَاءِ</p>
        <h2>وَالِاسْمُ اَلَّذِي فِيهِ اَلْأَلِفُ وَاللَّامُ؛ نَحْوُ: (الرَّجُلِ)، وَ(الغُلَامِ)</h2>
        <p>Isim yang di dalamnya terdapat alif-lam (ال); contohnya : الرَّجُلُ وَالْغُلَامُ</p>
        <h2>وَمَا أُضِيفَ إِلَى وَاحِدٍ مِنْ هَذِهِ اَلْأَرْبَعَةِ</h2>
        <p>Isim yang disandarkan kepada salah satu dari ke empat jenis di atas</p>
        <h2>وَالنَّكِرَةُ: كُلُّ اسْمٍ شَائِعٍ فِي جِنْسِهِ، لَا يَخْتَصُّ بِهِ وَاحِدٌ دُونَ آخَرَ</h2>
        <p>Sedangkan nakirah adalah "setiap isim yang bersifat umum pada jenisnya, tidak tertentu pada sesuatupun</p>
        <h2>وَتَقْرِيبُهُ كُلُّ مَا صَلَحَ دُخُولُ الأَلِفِ وَاللَّامِ عَلَيْهِ، نَحْوُ: (الرَّجُلِ)، و(الفَرَسِ)</h2>
        <p>untuk mendektakan paham, nakirah adalah setiap isim yang dapat menerima alif-lam contoh nya: Laki-laki itu : (الرَّجُلُ), Kuda itu : (الفَرَسُ)</p>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-mahfudotil-asma"></p>
          <h4>bab makhfudotil asma`</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab na`at</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab atof</h4>
          <p className="bi bi-chevron-right" data-url="/bab-atof"></p>
        </div>
      </div>
    </div>
  );
}

export default function Naat(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}