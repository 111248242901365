import React, { useState } from 'react';
import NavbarFile from '../home/navAppfile';
import { useNavigate } from 'react-router-dom';
import '../css/kalam.css';
import istisna from '../sourc/istisna.png'
import { Helmet } from 'react-helmet';


function Item(props){
    const [item,setItem]=useState(false)
    const toggleSet=()=>{
    setItem(!item)
}
    return(
        <>
            <h4 onClick={toggleSet}>{item? <i className="bi bi-chevron-down"></i>:<i className="bi bi-chevron-right"></i>}{props.h1}</h4>
            {item&&<>{props.p}</>}
        </>
    )
}

function Baris(){
    return(
        <>
        <Helmet>
              <title>bab istisna</title>
              <meta name="description" content="Huruf-huruf istisna ada 8 yaitu: إِلَّا - غَيْرُ - سِوَى - سُوَى - سَوَاءٌ - خَلَا - عَدَا- حَاشَا" />
        </Helmet>
        <h1 className='h1'>بَابُ اﻹِسْتِثْنَاءِ</h1>
        <h2>وَحُرُوفُ الِاسْتِثْنَاءِ ثَمَانِيَةٌ؛ وَهِيَ: (إِلَّا)، وَ(غَيْرُ)، وَ(سِوَى)، وَ(سُوَى)، وَ(سَوَاءٌ)، وَ(خَلَا)، وَ(عَدَا)، وَ(حَاشَا)</h2>
        <p>Huruf-huruf istisna ada 8 yaitu: إِلَّا - غَيْرُ - سِوَى - سُوَى - سَوَاءٌ - خَلَا - عَدَا- حَاشَا</p>
        <h2>فَالمُسْتَثْنَى بِـ(إِلَّا): يُنْصَبُ إِذَا كَانَ الكَلَامُ تَامًّا مُوجَبً</h2>
        <p>Mustasna dengan (إِلَّا) di-nashb-kan jika kalimat sempurna dan mujab</p>
        <h2>نَحْوُ: (قَامَ القَوْمُ إِلَّا زَيْدًا)، وَ(خَرَجَ النَّاسُ إِلَّا عَمْرًا)</h2>
        <p>contoh nya: قَامَ القَوْمُ إِلَّا زَيْدًا(Kaum itu telah berdiri kecuali Zaid), خَرَجَ النَّاسُ إِلَّا عَمْرًا(Orang-orang pada keluar kecuali Amr)</p>
        <img src={istisna} alt='contoh tamyiz'></img>
        <h2>وَإِنْ كَانَ الكَلَامُ مَنْفِيًّا تَامًّا: جَازَ فِيهِ البَدَلُ وَالنَّصْبُ عَلَى الِاسْتِثْنَاءِ</h2>
        <p>dan jika kalimatnya tam sempurna maka ia boleh dijadikan badal dan dibaca nasab atas kalimat yang dikecualikan</p>
        <h2>نَحْوُ: (مَا قَامَ القَوْمُ إِلَّا زَيْدٌ)، وَ(إِلَّا زَيْدًا)</h2>
        <p>contoh: مَا قَامَ القَوْمُ إِلَّا زَيْدٌ وَإِلَّا زَيْدًا(Kaum itu tidak ada yang berdiri kecuali Zaid)</p>
        <h2>وَإِنْ كَانَ الكَلَامُ نَاقِصًا: كَانَ عَلَى حَسَبِ العَوَامِلِ</h2>
        <p>Namun jika kalimatnya tidak sempurna maka ia di i`rob sebagaimana `amil yang masuk padanya</p>
        <h2>نَحْوُ: (مَا قَامَ إِلَّا زَيْدٌ)، وَ(مَا ضَرَبْتُ إِلَّا زَيْدًا)، وَ(مَا مَرَرْتُ إِلَّا بِزَيْدٍ)</h2>
        <p>contoh: مَا قَامَ إِلَّا زَيْدٌ(Tidak ada yang berdiri kecuali Zaid), مَا ضَرَبْتُ إِلَّا زَيْدًا(Tidak ada yang kupukul selain Zaid), مَا مَرَرْتُ إِلَّا بِزَيْدٍ(Aku tidak berpapasan kecuali dengan Zaid)</p>
        <h2>وَالْمُسْتَثْنَى بِـ(غَيْرٍ)، وَ(سِوَى)، وَ(سُوَى)، وَ(سَوَاءٍ): مَجْرُورٌ لَا غَيْرُ</h2>
        <p>Adapun mustasna dengan (غَيْرٍ، وَسِوَى، وَسُوَى، وَسَوَاءٍ): dibaca jar bukan yang lain</p>
        <h2>وَالمُسْتَثْنَى بِـ(خَلَا)، وَ(عَدَا)، وَ(حَاشَا): يَجُوزُ نَصْبُهُ وَجَرُّهُ</h2>
        <p>Sedangkan mustasna dengan (خَلَا، وَعَدَا، وَحَاشَا): boleh dibaca nasab dan boleh juga dibaca jar</p>
        <h2>نَحْوُ: (قَامَ القَوْمُ خَلَا زَيْدًا وَزَيْدٍ)، وَ(عَدَا عَمْرًا وَعَمْرٍو)، وَ(حَاشَا بَكْرًا وَبَكْرٍ)</h2>
        <p>contoh: قَامَ القَوْمُ خَلَا زَيْدًا، وَزَيْدٍ(Kaum itu berdiri kecuali Zaid), عَدَا عَمْرًا وَعَمْرٍو(Kecuali Amr), حَاشَا بَكْرًا وَبَكْرٍ(Kecuali Bakr)</p>
        </>
    )
}


function Halaman() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };


  const navigate = useNavigate();
  const openClick = (e) => {
    if (e.target.tagName === 'P') {
      const destination = e.target.getAttribute('data-url');
      const actionType = e.target.getAttribute('data-type'); 
      if (destination) {
        if (actionType === 'external') {
          window.open(destination, '_blank');
        } else {
          navigate(destination);
        }
      }
    }
  };

  return (
    <div className="coune">
      <div className={`containaa ${isActive ? 'active' : ''}`} id="sidebar">
        <div className="slip">
          <div className="navdownee">
            <Item
            h1='permulaan'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-kalam">kalam</p>
              <p data-url="/bab-ikrob">ikrob</p>
              <p data-url="/bab-makrifat">makrifat</p>
              <p data-url="/faslun">faslun</p>
              <p data-url="/bab-afal">af'al</p>
            </div>}
            />
            <Item
            h1='rofa`'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-marfuatil-asma">marfuatil asma`</p>
              <p data-url="/bab-fail">fa'il</p>
              <p data-url="/bab-naibulfail">naibul fa'il</p>
              <p data-url="/bab-mubtadak-khobar">mubtada khobar</p>
              <p data-url="/bab-awamilidakhilatialalmubtadakwakhobar">amil nawasikh</p>
            </div>}
            />
            <Item
            h1='nasab'
            p={
            <div onClick={openClick}>              
              <p data-url="/bab-mansubatil-asma">mansubatil asma`</p>
              <p data-url="/bab-mafulunbih">mafulunbih</p>
              <p data-url="/bab-masdar">masdar</p>
              <p data-url="/bab-zorofzaman-wamakan">zorof</p>
              <p data-url="/bab-hal">hal</p>
              <p data-url="/bab-tamyiz">tamyiz</p>
              <p data-url="/bab-istisna">istisna</p>
              <p data-url="/bab-laa">laa</p>
              <p data-url="/bab-munada">munada</p>
              <p data-url="/bab-mafulminajlih">maf`ul min ajlih</p>
              <p data-url="/bab-mafulmaah">maf`ul ma'ah</p>
            </div>}
            />
            <Item
            h1='khofad'
            p={
            <div onClick={openClick}>
                <p data-url="/bab-mahfudotil-asma">makhfudotil asma`</p>
            </div>}
            />
            <Item
            h1='mengikut'
            p={
            <div onClick={openClick}>
              <p data-url="/bab-naat">na'at</p>
              <p data-url="/bab-atof">atof</p>
              <p data-url="/bab-taukid">taukit</p>
              <p data-url="/bab-badal">badal</p>
            </div>}
            />
            <Item
            h1='sosial media'
            p={
            <div onClick={openClick}>
              <p data-url="https://instagram.com/khaisyah1507" data-type="external">instagram</p>
              <p data-url="https://wa.me/+6281380345525" data-type="external">whatsapp</p>
              <p data-url="mailto:khaizulaftar150704@gmail.com" data-type="external">email</p>
              <p data-url="https://www.tiktok.com/@k.afai?_t=8k0BgA4dhR4&_r=1" data-type="external">tiktok</p>
            </div>}
            />
            <Item
            h1='traktir'
            p={
            <div onClick={openClick}>
              <p data-url="https://saweria.co/Khaizulaftar" data-type="external">donate</p>
            </div>}
            />
          </div>
        </div>
        <div className="p" onClick={toggleSidebar}>
            {isActive ? <i className="bi bi-chevron-compact-right"></i>:<i className="bi bi-chevron-compact-left"></i>}
        </div>
      </div>
      <div className="containee">
      <div className={`contain ${isActive ? 'active' : ''}`} id="mainContent">
        <Baris/>
      </div>
      </div>
      <div className='contaislip' onClick={openClick}>
        <div className='containesliper'>
          <p className="bi bi-chevron-left" data-url="/bab-tamyiz"></p>
          <h4>bab tamyiz</h4>
        </div>
        <div className='containesliper'> 
          <h3>bab istisna</h3>
        </div>
        <div className='containesliper'> 
          <h4>bab laa</h4>
          <p className="bi bi-chevron-right" data-url="/bab-laa"></p>
        </div>
      </div>
    </div>
  );
}

export default function Istisna(){
    return(
        <>
        <NavbarFile/>
        <Halaman/>
        </>
    )
}